import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdCheck } from "react-icons/md";
import {
  setFormData,
  setLocalProducts,
  setAddedProducts,
  setIsStep2Done,
} from "../features/buy-now/action";



const HealthAndSafety = ({ products }) => {
  const dispatch = useDispatch();
  const { formData, isStep2Done, localProducts, addedProducts } = useSelector(
    (state) => state.buyNow,
  );

  useEffect(() => {
    if (isStep2Done === false) {
      dispatch(setLocalProducts(products));
      dispatch(setFormData({ products: products }));
    }
  }, [dispatch, isStep2Done, products]);


  const handleAdd = (id) => {
    if (!addedProducts.includes(id)) {
      dispatch(setAddedProducts([...addedProducts, id]));
    }
  };

  const handleRemove = (id) => {
    dispatch(setAddedProducts(addedProducts.filter((itemId) => itemId !== id)));
  };

  if (!localProducts?.length) return <p>Error loading products</p>;

  return (
    <div className="mx-auto flex w-full flex-col items-center justify-center">
      <p className="text-2xl">Health & Safety</p>
      {localProducts?.map((item, index) => (
    
        <div className="my-4 w-full" key={index}>
          <div className="max-w-full rounded-3xl border border-[#001219]/40 p-5 md:p-8">
            <img
              src={item?.media?.length > 0 ? item?.media[0] : ""}
              alt="product-logo"
              className="mx-auto h-auto w-full max-w-xs object-cover md:max-w-sm"
            />
            <div className="w-full">
              <h4 className="w-full py-4 text-center text-lg font-bold md:text-left md:text-xl">
                {item?.title}
              </h4>
              <p className="w-full text-center text-sm leading-6 md:text-left md:text-base md:leading-8">
                {item?.description}
              </p>

              <div className="mt-3 flex flex-col items-center gap-4 md:flex-row md:justify-between">
                {item.required ? (
                  <p className="rounded-lg text-center leading-6 md:text-left">
                    ** Required For Warranty
                  </p>
                ) : (
                  <div className="flex flex-col items-center gap-4 md:flex-row">
                    {addedProducts.includes(item._id) ? (
                      <div className="flex items-center gap-3 rounded-full bg-[#5EC0FC] px-6 py-2 md:px-7 md:py-3">
                        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
                          <MdCheck color={"#5EC0FC"} />
                        </div>
                        <span className="text-sm font-bold text-white md:text-base">
                          Added
                        </span>
                      </div>
                    ) : (
                      <button
                        className="rounded-full bg-[#5EC0FC] px-6 py-2 text-sm font-bold text-white md:px-7 md:py-3 md:text-base"
                        onClick={() => handleAdd(item._id)}
                      >
                        Add
                      </button>
                    )}

                    {addedProducts.includes(item._id) && (
                      <button
                        className="flex items-center rounded-full border border-black bg-transparent px-6 py-2 text-sm md:px-7 md:py-3 md:text-base"
                        onClick={() => handleRemove(item._id)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                )}
                <p className="text-center text-sm leading-6 md:text-left md:text-base md:leading-8">
                  ${item?.price}
                </p>
              </div>
            </div>
          </div>
        </div>
   
      ))}
         
    </div>
  );
};

export default HealthAndSafety;
