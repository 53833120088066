
import React, { createContext, useState, useContext } from 'react';


const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
    const [tabPosition, setTabPosition] = useState(1)
    const [selectedBreed, setSelectedBreed] = useState({});
    const [isSelectedBreed, setIsSelectedBreed] = useState(false);

    return (
        <GlobalContext.Provider value={{ tabPosition, setTabPosition, selectedBreed, setSelectedBreed, isSelectedBreed, setIsSelectedBreed }}>
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () => {
    const context = useContext(GlobalContext)
    if (!context) {
        throw new Error('useGlobalContext must be used within a TabProvider')
    }
    return context
}
