import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

function Accordion({ formData }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const hasData = (data) => {
    if (!data || typeof data !== "object") {
      return false; // Return false for null, undefined, or non-object types
    }

    return Object.values(data).some(
      (value) =>
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === "object" &&
          value !== null &&
          Object.keys(value).length > 0) ||
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value)),
    );
  };

  return (
    <div className="w-8xl m-3 mx-auto">
      <button
        onClick={toggleAccordion}
        className="flex w-full items-center justify-between rounded-lg bg-[#001219] px-4 py-2 font-bold text-white focus:outline-none"
      >
        {isOpen ? "Hide Details" : "Details"}
        <IoIosArrowDown size={24} />
      </button>

      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="rounded-b-lg bg-gray-100 p-4">
          {/* Delivery Method */}
          {formData?.deliveryMethod && (
            <div className="mb-4">
              <h1 className="p-2 font-semibold">Delivery Method</h1>
              <hr />
              <div className="p-2">
                <p>{formData.deliveryMethod}</p>
              </div>
            </div>
          )}

          {/* Shipping Method */}
          {formData?.pickupPoint && (
            <div className="mb-4">
              <h1 className="p-2 font-semibold">Shipping Method</h1>
              <hr />
              <div className="p-2">
                <p>{formData.pickupPoint}</p>
              </div>
            </div>
          )}

          {/* Step 1: Selected Products */}
          {hasData(formData?.products) && (
            <div className="mb-4">
              <h1 className="p-2 font-semibold">Selected Products</h1>
              <hr />
              {formData.products.map((item, index) => (
                <div className="p-2" key={index}>
                  <p className="text-gray-700">{item?.title || item?.name}</p>
                </div>
              ))}
            </div>
          )}

          {/* Step 2: Billing & Destination Details */}
          {hasData(formData?.contactInformation) ||
          hasData(formData?.destinationAddress) ? (
            <div className="mb-4">
              <h1 className="p-2 font-semibold">
                Billing & Destination Details
              </h1>
              <hr />
              {hasData(formData?.contactInformation) && (
                <div className="p-2">
                  <h2 className="font-bold">Contact Information</h2>
                  <p>Email: {formData?.contactInformation?.emailAddress}</p>
                  <p>Phone: {formData?.contactInformation?.phone}</p>
                </div>
              )}
              {hasData(formData?.destinationAddress) && (
                <div className="p-2">
                  <h2 className="font-bold">Destination Address</h2>
                  <p>
                    {formData?.destinationAddress?.firstName}{" "}
                    {formData?.destinationAddress?.lastName}
                  </p>
                  <p>{formData?.destinationAddress?.address}</p>
                  <p>
                    {formData?.destinationAddress?.city},{" "}
                    {formData?.destinationAddress?.state}
                  </p>
                </div>
              )}
            </div>
          ) : null}

          {/* Step 3: Order Summary */}
          {hasData({
            puppyTotal: formData?.puppyTotal,
            salesTax: formData?.salesTax,
            subTotal: formData?.subTotal,
            totalPrice: formData?.totalPrice,
          }) && (
            <div className="mb-4">
              <h1 className="p-2 font-semibold">Order Summary</h1>
              <hr />
              <div className="p-2">
                {formData?.puppyTotal !== undefined && (
                  <p>
                    <span className="font-bold">Puppy Total:</span> $
                    {formData?.puppyTotal}
                  </p>
                )}
                {formData?.salesTax !== undefined && (
                  <p>
                    <span className="font-bold">Sales Tax:</span> $
                    {formData?.salesTax?.toFixed(2)}
                  </p>
                )}
                {formData?.subTotal !== undefined && (
                  <p>
                    <span className="font-bold">Subtotal:</span> $
                    {formData?.subTotal}
                  </p>
                )}
                {formData?.totalPrice !== undefined && (
                  <p>
                    <span className="font-bold">Total Price:</span> $
                    {formData?.totalPrice?.toFixed(2)}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
