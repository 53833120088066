import React, { useEffect, useMemo, useState } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import PupsForm from "./PupsForm";
import PupsTable from "./PupsTable";
import { availablePups_tableData } from "../../../utils/data";
import {
  useAllPups,
  useCreatePups,
  useDeletePupsMutation,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { getDate } from "../../../utils";
import { RxOpenInNewWindow } from "react-icons/rx";
import { Modal } from "antd";
import { IoEyeOutline } from "react-icons/io5";

const Available = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateMutation = useUpdatePupsMutation();
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [status, setStatus] = useState(false);
  const [createId, setCreateID] = useState(null);
  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });
  const toast = useToast();

  const extendedClose = () => {
    onClose();
    setSelectedIdEdit(null);
    setSelectedData([]);
  };
  const { data: puppies, isLoading, error } = useAllPups("/api/puppies");
  console.log("puppies===>", puppies);

  const createDeleteMutation = useDeletePupsMutation();
  const createMutation = useCreatePups();

  const handle = (id) => {
    localStorage.removeItem("puppyFormData");
    setSelectedIdEdit(id);
    onOpen();
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = (id) => {
    const data = {
      status,
    };

    updateMutation.mutate(
      { apiUrl: `/api/puppy-status/${selectedId}`, data },

      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Puppy Status Updated Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error Updated Puppy Status");
          toast({
            title: "Error Updated Puppy Status",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = puppies?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  const duplicateClick = (id) => {
    setCreateID(id);
    console.log(id);
  };

  const handleDuplicate = () => {
    console.log("duplicate");
    const filter = puppies?.filter((puppy) => puppy._id === createId);
    console.log("filter===> ", filter);

    const data = {
      puppyInformation: {
        breed: filter[0]?.puppyInformation?.breed?._id,
        breeder: filter[0]?.puppyInformation?.breeder,
        parent: filter[0]?.puppyInformation?.parent,
        kennel: filter[0]?.puppyInformation?.kennel,
        cageNumber: filter[0]?.puppyInformation?.cageNumber,
        // color: filter[0]?.puppyInformation?.color,
        idNumber: filter[0]?.puppyInformation?.idNumber,
        usdaId: filter[0]?.puppyInformation?.usdaId,
        localPickup: filter[0]?.puppyInformation?.localPickup,
        gender: filter[0]?.puppyInformation?.gender,
        registration: filter[0]?.puppyInformation?.registration,
        akcAppNumber: filter[0]?.puppyInformation?.akcAppNumber,
        dateAcquired: filter[0]?.puppyInformation?.dateAcquired,
        dateOfBirth: filter[0]?.puppyInformation?.dateOfBirth,
        video: filter[0]?.puppyInformation?.video,
        weight: filter[0]?.puppyInformation?.weight,
        testResults1: filter[0]?.puppyInformation?.testResults1 ?? null,
        meds1: filter[0]?.puppyInformation?.meds1 ?? null,
        testResults2: filter[0]?.puppyInformation?.testResults2 ?? null,
        meds2: filter[0]?.puppyInformation?.meds2 ?? null,
        testResults3: filter[0]?.puppyInformation?.testResults3 ?? null,
        meds3: filter[0]?.puppyInformation?.meds3 ?? null,
        testResults4: filter[0]?.puppyInformation?.testResults4 ?? null,
        meds4: filter[0]?.puppyInformation?.meds4 ?? null,
        dateSold: filter[0]?.puppyInformation?.dateSold,
        wholesaleTransferDate:
          filter[0]?.puppyInformation?.wholesaleTransferDate,
        purchaser: filter[0]?.puppyInformation?.purchaser,
        cost: filter[0]?.puppyInformation?.cost,
      },
      listingInformation: {
        active: filter[0]?.listingInformation?.active,
        featured: filter[0]?.listingInformation?.featured,
        perfect10: filter[0]?.listingInformation?.perfect10,
        doNotList: filter[0]?.listingInformation?.doNotList,
        nurturingNotice: filter[0]?.listingInformation?.nurturingNotice,
        heading: filter[0]?.listingInformation?.heading,
        name: filter[0]?.listingInformation?.name,
        title: filter[0]?.listingInformation?.title,
        description: filter[0]?.listingInformation?.description,
        whatComesWithDog: filter[0]?.listingInformation?.whatComesWithDog,
        price: filter[0]?.listingInformation?.price,
        adultWeight: filter[0]?.listingInformation?.adultWeight,
        quantity: filter[0]?.listingInformation?.quantity,
        makeOffer: filter[0]?.listingInformation?.makeOffer,
        offerPrice: filter[0]?.listingInformation?.offerPrice,
        photos: filter[0]?.listingInformation?.photos,
        registrationPaperwork:
          filter[0]?.listingInformation?.registrationPaperwork,
        presalePups: filter[0]?.listingInformation?.presalePups,
        status: true,
      },
    };

    createMutation.mutate(
      {
        apiUrl: "/api/puppy",
        data,
      },
      {
        onSuccess: () => {
          toast({
            title: "Puppy created successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setCreateID(null);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        },
        onError: (error) => {
          toast({
            title: error.response?.data?.details?.includes("duplicate")
              ? "Puppy Already Exist"
              : error.response?.data?.details ||
                error.message ||
                "Error creating Puppy",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
    if (selectedId) {
      handleDelete();
      console.log("selectedId", selectedId);
    }
    if (createId) {
      handleDuplicate();
    }
  }, [selectedIdEdit, selectedId, createId]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const filteredData = availablePups_tableData.filter(
    (item) =>
      item.available.includes(filters.available) &&
      item.details.toLowerCase().includes(filters.details.toLowerCase()) &&
      item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
      item.checkboxes
        .toLowerCase()
        .includes(filters.checkboxes.toLowerCase()) &&
      item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
      item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  );

  console.log(filteredData, "filteredData");
  const [searchTerm, setSearchTerm] = useState("");

  const sortedPuppies = useMemo(() => {
    return puppies
      ?.filter(
        (puppy) =>
          puppy?.listingInformation?.status &&
          (puppy?.listingInformation?.name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            puppy?.puppyInformation?.color
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())),
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [puppies, searchTerm]);

  const handleViewListing = (listingId) => {
    window.open(`/puppies/pups-details/${listingId}`, "_blank");
  };
  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
        >
          Add Pups
        </button>

        <GlobalModalV1 onClose={extendedClose} isOpen={isOpen}>
          <PupsForm onClose={extendedClose} isOpen={isOpen} />
        </GlobalModalV1>
      </div>
      <div className="my-10">
        <div className="rounded-md border-2 border-gray-200 shadow-md">
          <div className="grid grid-cols-12 gap-4 p-4">
            {/* Image */}
            <div className="col-span-3 ml-14 flex flex-col items-start justify-start">
              <div className="font-bold">Image</div>
            </div>

            {/* Details */}
            <div className="col-span-4 flex flex-col items-center justify-start text-center">
              <input
                type="text"
                placeholder="Search by puppy name"
                name="details"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>

            {/* Description */}
            <div className="col-span-4 flex flex-col items-center justify-start text-center">
              <input
                type="text"
                placeholder="Description"
                name="desc"
                value={filters.desc}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
          </div>

          {sortedPuppies &&
            sortedPuppies.map((item, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-t border-gray-200 p-4"
                key={index}
              >
                {/* Image Section */}
                <div className="col-span-3 flex items-center justify-center">
                  <div className="h-[90%] w-[230px]">
                    <img
                      src={
                        item?.listingInformation?.photos?.[0] ||
                        "default-image-url"
                      }
                      alt="breed-img"
                      className="h-full w-full rounded-lg object-cover"
                    />
                  </div>
                </div>

                {/* Details Section */}
                <div className="col-span-4 flex flex-col items-start gap-2 border border-gray-200 p-3">
                  <p className="font-bold">
                    Name: {item?.listingInformation?.name || "No Name"}
                  </p>
                  <p>
                    Cage Number:{" "}
                    {item?.puppyInformation?.cageNumber || "No Cage Number"}
                  </p>
                  <p>
                    <span className="font-semibold">Breed</span>:{" "}
                    {item?.puppyInformation?.breed?.breedInformation
                      ?.breedName || "No Color Name"}
                  </p>
                  <p>
                    <span className="font-semibold">Gender</span>:{" "}
                    {item?.puppyInformation?.gender || "No Gender"}
                  </p>
                  <p>
                    {item?.listingInformation?.makeOffer ? (
                      <>
                        <span className="font-semibold">Price: $ </span>
                         {item?.listingInformation?.offerPrice || "No Price "}
                      </>
                    ) : (
                      <>
                        <span className="font-semibold">Price: $ </span>
                        {item?.listingInformation?.price || "No Price "}
                      </>
                    )}
                  </p>
                  <p>
                    <span className="font-semibold">Weight</span>:{" "}
                    {item?.puppyInformation?.weight || "No Weight"} lbs
                  </p>
                  <p>
                    <span className="font-semibold">ID Number</span>:{" "}
                    {item?.puppyInformation?.idNumber || "No Id Number"}
                  </p>
                  {/* <p>
                    <span className="font-semibold">Pup Color</span>:{" "}
                    {item?.puppyInformation?.color || "No Color"}
                  </p> */}
                  <div className="mt-2 flex space-x-2">
                    <button
                      onClick={() => handle(item?._id)}
                      className="rounded bg-blue-500 px-2 py-1 text-sm text-white"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleViewListing(item?._id)}
                      className="flex items-center justify-center gap-4 rounded bg-green-500 px-2 py-1 text-sm text-white"
                    >
                      View
                      <RxOpenInNewWindow />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(item?._id)}
                      className="rounded bg-red-500 px-2 py-1 text-sm text-white"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => duplicateClick(item._id)}
                      className="rounded bg-[#868686] px-2 py-1 text-sm text-white"
                    >
                      Duplicate
                    </button>
                    <button className="flex items-center justify-evenly gap-4 rounded bg-[#001219] px-2 py-1 text-sm text-white">
                      <IoEyeOutline size={"22px"} />
                      <span>
                        {item?.listingInformation?.productView &&
                          item?.listingInformation?.productView}
                      </span>
                    </button>
                  </div>
                </div>

                {/* Description Section */}
                <div className="col-span-4 flex flex-col gap-2 border border-gray-200 p-2">
                  <p>
                    <span className="font-semibold">Description</span> :{" "}
                    {item?.listingInformation?.description || "No Description"}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* <PupsTable /> */}
      <GlobalModalV1 isOpen={isOpen} onClose={extendedClose}>
        <PupsForm
          onClose={extendedClose}
          isUpdateModal={true}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this Puppy?</p>
      </Modal>
    </div>
  );
};

export default Available;
