import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";


import { create, Delete, fetchAll, update } from "../Apis/Apis.js";
import { GET_ALL_REAL_BREEDS } from "./realBreedType.js";


export const useAllRealBreeds = (apiUrl) => {
  return useQuery({
    queryKey: GET_ALL_REAL_BREEDS,
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateRealBreed = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Breed:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
  });
};

export const useDeleteRealBreedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error deleting  Breeds:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
  });
}

export const useUpdateRealBreedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error Updating Reviews", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS)
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_REAL_BREEDS)
    },
  });
}