import React, { useState, useEffect } from "react";

const CustomTabs = ({
  tabs,
  tabClassName,
  activeTabClassName,
  tabPositionClassName,
  tabPosition,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[tabPosition].label);

  return (
    <div className={`${tabPositionClassName}`}>
      <div className="w-full px-4 md:px-0">
        <div className="flex flex-wrap justify-center gap-4 md:gap-8">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`rounded-full transition-colors duration-300 focus:outline-none ${
                activeTab === tab.label ? activeTabClassName : tabClassName
              }`}
              onClick={() => setActiveTab(tab.label)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-6 md:mt-8">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`transition-opacity duration-300 ${
              activeTab === tab.label ? "block opacity-100" : "hidden opacity-0"
            }`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>

    // <div className={`${tabPositionClassName}`}>
    //   <div className="">
    //     <div className="flex">
    //       {tabs.map((tab, index) => (
    //         <button
    //           key={index}
    //           className={`rounded-full transition-colors duration-300 focus:outline-none ${index > 0 ? "-ml-4" : ""} ${activeTab === tab.label ? activeTabClassName : tabClassName}`}
    //           onClick={() => setActiveTab(tab.label)}
    //         >
    //           {tab.label}
    //         </button>
    //       ))}
    //     </div>
    //   </div>
    //   <div className="mt-8">
    //     {tabs.map((tab, index) => (
    //       <div
    //         key={index}
    //         className={`transition-opacity duration-300 ${activeTab === tab.label ? "opacity-100" : "opacity-0"} ${activeTab === tab.label ? "block" : "hidden"}`}
    //       >
    //         {tab.content}
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};

export default CustomTabs;
