import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import CustomPieChart from "../../../components/CustomPieChart";
import CustomLineChart from "../../../components/CustomLineChart";
import {
  pupsListing_salesTableData,
  pupsListing_tableData,
} from "../../../utils/data";
import { useAllInActivePups, useAllPupActiveStatistics, useAllPups, useAllPupStatistics } from "../../../features/pups/pupsService";



const PupListingDashboard = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const {
    data: activePups,
    isLoading,
    isError,
  } = useAllPupActiveStatistics("/api/pup-active");
  console.log("All active pups====>", activePups);

  const {
    data: inatcive
  } = useAllInActivePups("/api/pup-statistics")

  console.log("Inactive pups==>", inatcive)


  const {data: pupStatistics} = useAllPupStatistics("/api/pupBreed-statistics")
  console.log("Pup statistics==>", pupStatistics);

  // const activePups = async ()=> {
  //   const response = await axios.get("http://localhost:5000/api/pup-active")
  //   console.log("Active pups===>", response.data)
  //   return response
  // }

  // activePups()

  const data01 = [
    {
      name: "Group A",
      value: 400,
    },
    {
      name: "Group B",
      value: 300,
    },
    {
      name: "Group C",
      value: 300,
    },
  ];
  const data02 = [
    {
      name: "Group A",
      value: 400,
    },
    {
      name: "Group B",
      value: 300,
    },
  ];
  const data03 = [
    {
      name: "Group A",
      value: 400,
    },
    {
      name: "Group B",
      value: 100,
    },
  ];
  const data04 = [
    {
      name: "Group A",
      value: 400,
    },
    {
      name: "Group B",
      value: 200,
    },
    {
      name: "Group c",
      value: 100,
    },
    {
      name: "Group D",
      value: 300,
    },
    {
      name: "Group E",
      value: 50,
    },
    {
      name: "Group F",
      value: 70,
    },
    {
      name: "Group G",
      value: 150,
    },
  ];

  const COLORS01 = ["#EFF4FB", "#8CDCFF", "#599CFF"];
  const COLORS02 = ["#6F6CD9", "#FF73F1"];
  const COLORS03 = ["#8CC8FF", "#EDEF7F"];
  const COLORS04 = [
    "#EFFEDD",
    "#D0FC99",
    "#B7F06E",
    "#8AD729",
    "#B7F06E",
    "#D0FC99",
  ];
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const pupsSales = pupsListing_salesTableData.map((item, index) => item.data);
  console.log("Pups sales data==>", pupsSales);
  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="md:gird-cols-1 grid gap-4 lg:grid-cols-4 xl:grid-cols-7">
        <div className="col-span-1 rounded-[20px] bg-[#001219] text-white">
          <div className="flex flex-col items-center justify-center p-12">
            {/* <div className="flex items-center gap-2">
              <p className="font-bold">Total Sales</p>
            </div> */}
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-4xl font-bold">{inatcive?.totalPups}/{inatcive?.inactivePups}</p>
                <p className="text-sm font-semibold">Available/Inactive Pups</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-span-2 grid grid-cols-2 rounded-[20px] border bg-white px-4">
          <div className="flex flex-col justify-center p-4">
            <p className="text-3xl font-bold">
              Mate
              <br />
              52%
            </p>
          
          </div>

          <CustomPieChart
            COLORS={COLORS01}
            onPieEnter={onPieEnter}
            data01={data01}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            outerRadius={115}
          />

     
        </div> */}
        {/* <div className="rounded-[20px] border bg-white px-4">
          <div className="flex justify-center items-center p-4 min-h-2">
            <p className="text-3xl font-bold text-center">
              Active
              <br />
              {activePups?.activePups ?? 0}{" "}
            </p>{" "}
   
          </div>

          <CustomPieChart
            COLORS={COLORS02}
            onPieEnter={onPieEnter}
            data01={data02}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            outerRadius={115}
          />
        </div> */}

        <div className="flex justify-center items-center gap-6 pt-8 bg-white rounded-[20px] ">
          <div className="flex flex-col gap-2 text-center">
            <p className="text-2xl font-bold text-center">Active Pups</p>
            <p className="text-4xl font-semibold">{activePups?.activePups ?? 0}{" "}</p>
          </div>
        </div>

        {/* <div className="col-span-2 grid grid-cols-2 rounded-[20px] border bg-white px-4">
          <div className="flex flex-col justify-center p-4">
            <p className="text-3xl font-bold">
              Presale
              <br />
              468
            </p>{" "}
           
          </div>

          <CustomPieChart
            COLORS={COLORS03}
            onPieEnter={onPieEnter}
            data01={data03}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            outerRadius={115}
          />
        </div> */}
      </div>
      {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <div className="grid grid-cols-2 rounded-[20px] border bg-white px-4">
          <div className="flex flex-col justify-center p-4">
            <p className="text-3xl font-bold">
              In-House
              <br />
              17
            </p>{" "}
         
          </div>

          <CustomPieChart
            COLORS={COLORS04}
            onPieEnter={onPieEnter}
            data01={data04}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            outerRadius={90}
          />
        </div>
      </div> */}
      <div className="rounded-[20px] bg-white p-8">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4">
          {/* {pupsListing_tableData.map((item, index) => (
            <div
              className="border-r border-black pr-10 last:border-r-0"
              key={index}
            >
              <h4 className="text-lg font-bold">{item.title}</h4>
              <div className="w-full">

                <div className="flex justify-between py-2">
                  {item.tableHead.map((heading, index) => (
                    <p key={index} className="font-bold">
                      {heading}
                    </p>
                  ))}
                </div>
            
                {item.data.map((row, rowIndex) => (
                  <div key={rowIndex} className="flex justify-between py-2">
                    {Object.values(row).map((cell, cellIndex) => (
                      <p key={cellIndex}>{cell}</p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))} */}
          {pupStatistics?.map((item, index) => (
            <div
              className="border-r border-black pr-10 last:border-r-0"
              key={index}
            >
              <h4 className="text-lg font-bold">{item.title}</h4>
              <div className="w-full">

                <div className="flex justify-between py-2">
                  {item.tableHead.map((heading, index) => (
                    <p key={index} className="font-bold">
                      {heading}
                    </p>
                  ))}
                </div>
            
                {item.data.map((row, rowIndex) => (
                  <div key={rowIndex} className="flex justify-between py-2">
                    {Object.values(row).map((cell, cellIndex) => (
                      <p key={cellIndex}>{cell}</p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))} 
        </div>
      </div>
    </div>
  );
};

export default PupListingDashboard;
