import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { resetBuyNowState } from "../features/buy-now/action";
import { useDispatch } from "react-redux";
import { clearCart } from "../features/cart/action";

const OrderConfirmedPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const saveOrder = async () => {
      try {
        // Get the pending order data from localStorage
        const pendingOrderString = localStorage.getItem('pendingOrderData');
        if (!pendingOrderString) {
          navigate('/');
          return;
        }

        const pendingOrder = JSON.parse(pendingOrderString);
        const { orderData, sessionId } = pendingOrder;

        if (pendingOrder && sessionId) {
          // Save order to database
          const saveResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/order`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData),
          });

          if (!saveResponse.ok) {
            throw new Error('Failed to save order');
          }

          // Clear the pending order from localStorage
          localStorage.removeItem('pendingOrderData');
          dispatch(resetBuyNowState());
          dispatch(clearCart());
          // Add success toast
          toast({
            title: "Order Confirmed",
            description: "Your order has been successfully processed!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          throw new Error('Payment verification failed');
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "There was a problem confirming your order. Please contact support.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    saveOrder();
  }, []);

  if (isLoading) {
    return (
      <div className="font-roboto mx-auto mt-[200px] h-[500px] max-w-[480px] text-center">
        <p>Processing your order...</p>
      </div>
    );
  }

  return (
    <div className="font-roboto mx-auto mt-[200px] h-[500px] max-w-[480px] text-center text-[18px] leading-[24px]">
      <h1 className="my-10 text-3xl font-bold">Thank You For Your Purchase!</h1>
      <div className="relative mb-8 inline-block">
        <div
          className="relative z-10 h-[152px] w-[152px] bg-cover bg-center"
          style={{ backgroundImage: "url('/images/check-confetti.svg')" }}
        ></div>
        <div
          className="absolute left-0 top-0 h-[152px] w-[152px] bg-center bg-no-repeat"
          style={{ backgroundImage: "url('/images/check.svg')" }}
        ></div>
      </div>

      <p>
        <strong>Your order details have been recorded</strong>
      </p>

      <p>
        Our team will get back to you shortly for further order confirmation.
      </p>
      <p>You'll hear from us soon.</p>
      <div className="mt-6">
        <Link to="/" className="font-semibold text-blue-600">
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default OrderConfirmedPage;
