import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { GET_ALL_BREEDS } from "./BreedsTypes.js";
import { create, Delete, fetchAll, getById, update } from "../Apis/Apis";

// Hooks
export const useAllBreeds = (apiUrl) => {
  return useQuery({
    queryKey: GET_ALL_BREEDS,
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateBreeds = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      // Optionally return a context containing data to use when for example rolling back
      // await queryClient.cancelQueries(GET_ALL_BREEDS);
      // const previousProducts = queryClient.getQueryData(GET_ALL_BREEDS);
      // queryClient.setQueryData(GET_ALL_BREEDS, (old) => [
      //   ...old,
      //   newProduct,
      // ]);
      // return { previousProducts };
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
    onError: (err, newProduct, context) => {
      // An error happened!
      console.log("Error creating category:", err);
      // if (context?.previousProducts) {
      //   queryClient.setQueryData(GET_ALL_BREEDS, context.previousProducts);
      // }
    },
    onSuccess: (data) => {
      // Boom baby!
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
    onSettled: () => {
      // Error or success... doesn't matter!
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
  });
};

export const useDeleteBreedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Breed:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
  });
};

export const useUpdateBreedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error updating product:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_BREEDS);
    },
  });
};

export const useGetBreedById = (apiUrl) => {

  return useQuery({
    queryKey: ["getBreedById", apiUrl], 
    queryFn: async () => {
      try {
        const data = await getById(apiUrl); 
        return data;
      } catch (error) {
        console.error("Error fetching data:", error); 
        throw error;
      }
    },
    enabled: !!apiUrl, 
  });
};