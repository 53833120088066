import { message } from "antd";
import moment from "moment";
import { NextArrow, PrevArrow } from "./data";

export function getPuppyAgeInWeeks(dob) {
  const birthDate = new Date(dob);
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - birthDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const ageInWeeks = Math.floor(differenceInDays / 7);
  return ageInWeeks;
}
// export function convertAge(dateStr) {
//   const date = new Date(dateStr);
//   const options = {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     timeZoneName: "short",
//   };
//   const formattedDate = date.toLocaleDateString("en-US", options);
//   return formattedDate;
// }
export function convertAge(dateStr) {
  const date = new Date(dateStr);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}


// export function getDate(date) {
//   const formattedDate = new Date(date).toISOString().split('T')[0];
//   return formattedDate;
// }

export function getDate(date) {
  const formattedDate = moment(date).format("MM/DD/YYYY hh:mm A");
  return formattedDate;
}

//Get location details
export const getLocationByZip = async (zipcode) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`,
  );
  const data = await response.json();

  if (data.status === "OK") {
    const results = data.results[0];

    console.log("Location Result==>", results);

    const city = results.address_components.find((component) =>
      component.types.includes("locality"),
    )?.long_name;
    const postalCode = results.address_components.find((component) =>
      component.types.includes("postal_code"),
    )?.long_name;
    const state = results.address_components.find((component) =>
      component.types.includes("administrative_area_level_1"),
    )?.long_name;
    const stateAbbreviation = results.address_components.find((component) =>
      component.types.includes("administrative_area_level_1"),
    )?.short_name;

    const country = results.address_components.find((component) =>
      component.types.includes("country"),
    );

    const location = results.geometry.location;

    return { city, country, postalCode, stateAbbreviation, state, location };
  } else {
    console.error("Geocoding failed:", data.status);
  }
};

//Delete Images Handler
export const deleteImages = (file, setFileList) => {
  if (typeof setFileList === 'function') {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
  } else {
    console.error('Provided setter is not a function');
  }
};

//Image Preview Handler

export const handleBeforeUpload = (file, setFileList, supportedFormats = ["image/png", "image/jpeg", "image/jpg", "image/webp"]) => {
  const isSupportedFormat = supportedFormats.includes(file.type);

  if (!isSupportedFormat) {
    message.error(`You can only upload ${supportedFormats.join(", ")} files!`);
    return false;
  }

  const newFile = {
    ...file,
    uid: file.uid,
    name: file.name,
    status: "done",
    originFileObj: file,
  };

  setFileList((prev) => [...prev, newFile]);
  return false;
};


//Breeds/Parent slider setting variable

const calculateSlidesToShow = (items = []) => {
  return Math.min(items.length || 1, 7); // Ensure at least 1 slide is shown
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};



export const breedSettings = (items = []) => {
  const slidesToShow = calculateSlidesToShow(items);
  const isSingleItem = items.length === 1;

  return {
    ...settings,
    slidesToShow,
    // slidesToScroll: isSingleItem ? 1 : slidesToShow,
    infinite: !isSingleItem,
    centerMode: !isSingleItem && slidesToShow < items.length,
    autoplay: !isSingleItem,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(slidesToShow, 4),
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: Math.min(slidesToShow, 1),
        },
      },
    ],
  };
};


// const calculateSlidesToShow = (items = []) => {
//   return Math.min(items.length || 1, 7);
// };

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   prevArrow: <PrevArrow />,
//   nextArrow: <NextArrow />,
// };

// export const breedSettings = (items = []) => {
//   const slidesToShow = calculateSlidesToShow(items);

//   return {
//     ...settings,
//     slidesToShow,
//     slidesToScroll: 1,
//     centerMode: slidesToShow < items.length,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: Math.min(slidesToShow, 4),
//         },
//       },
//       {
//         breakpoint: 640,
//         settings: {
//           slidesToShow: Math.min(slidesToShow, 1),
//         },
//       },
//     ],
//   };
// };


