import React, { useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
  availablePups_tableData,
  gallery_tableData,
} from "../../../utils/data";
import {
  useAllReviews,
  useDeleteReviewsMutation,
  useUpdateReviewMutation,
} from "../../../features/reviews/reviewsService.js";
import toast from "react-hot-toast";
import AntModal from "../../../components/AntModal.jsx";
import { RxOpenInNewWindow } from "react-icons/rx";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import GalleryForm from "./GalleryForm.jsx";

const TableGallery = () => {
  const { data: reviews, isLoading, error } = useAllReviews("/api/reviews");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentReviewId, setCurrentReviewId] = useState(null);
  const [updateOrdelete, setUpdateOrDelete] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [currentReviewApproval, setCurrentReviewApproval] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const UpdateMutation = useUpdateReviewMutation();
  const DeleteMutation = useDeleteReviewsMutation();

  console.log(reviews, "=>>>>review");

  //making a approved review function
  const approvedReview = async (e, id, type, approvalStatus) => {
    e.stopPropagation();
    setCurrentReviewId(id);
    setIsModalVisible(true);
    setUpdateOrDelete(true);
    setActionType(type);
    setCurrentReviewApproval(approvalStatus);
  };

  const createDeleteMutation = useDeleteReviewsMutation();

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setCurrentReviewId(id);
    setIsModalVisible(true);
    setUpdateOrDelete(false);
  };

  const handleConfirm = async () => {
    setIsModalVisible(false);
    setCurrentReviewId(null);

    if (updateOrdelete) {
      const data = {
        approval: !currentReviewApproval,
      };
      try {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/reviews/${currentReviewId}`,
          data,
        });
      } catch (error) {
        toast({
          title: error.response?.data?.details?.includes("duplicate")
            ? "update Review error"
            : error.response?.data?.details ||
              error.message ||
              "Error updating Review",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      createDeleteMutation.mutate(
        { apiUrl: `/api/reviews/${currentReviewId}` },
        {
          onSuccess: () => {
            toast({
              title: "Review Deleted Successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
          onError: (error) => {
            console.log("Error deleted Review");
            toast({
              title: "Error deleted review",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentReviewId(null);
    setActionType(null);
  };

  const handleViewFeedback = (reviewId) => {
    window.open(`/customer-photos/details/${reviewId}`, "_blank");
  };

  // const handleFilterChange = (e) => {
  //   setFilters({
  //     ...filters,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  //   const filteredData = gallery_tableData.filter(
  //     (item) =>
  //       item.available.includes(filters.available) &&
  //       item.details.toLowerCase().includes(filters.details.toLowerCase())
  //       &&
  //       item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
  //       item.checkboxes
  //         .toLowerCase()
  //         .includes(filters.checkboxes.toLowerCase()) &&
  //       item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
  //       item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  //   );

  const handleEditReview = (id) => {
    const filter = reviews?.filter((item) => item?._id === id);
    console.log("Filtered Review===>", filter);
    setSelectedData(filter);
    onOpen();
    // setSelectedIdEdit(null);
  };

  return (
    <div className="my-10">
      <div className="my-10">
        <div className="rounded-xl border border-gray-300">
          <div className="grid grid-cols-12 gap-4 bg-white p-4">
            <div className="col-span-5 grid grid-cols-2 gap-4">Details</div>
            <div className="col-span-1 font-bold">
              <input
                type="text"
                placeholder="Image Gallery"
                name="views"
                // value={filters.views}
                // onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
          </div>
          {reviews &&
            reviews.map((item, index) => (
              <div
                className={`grid grid-cols-12 gap-4 border-t border-gray-200 p-6 ${
                  index % 2 === 0 ? "bg-white/70" : "bg-transparent"
                }`}
                key={index}
              >
                <div className="col-span-5 gap-4">
                  <div>
                    <div>
                      <p className="mb-4 font-bold">Owner Details</p>
                      <div>
                        <p>
                          {item.familyName && (
                            <span>Family Name: {item.familyName} </span>
                          )}
                          {/* {item.lastName} */}
                        </p>
                        {/* <p>
                          {item.email && <span>Email: {item.email}</span>}
                          {item.cellPhone && <span>• {item.cellPhone}</span>}
                        </p> */}
                      </div>
                    </div>
                    <div className="mt-8">
                      <p className="mb-4 font-bold">Pup Details</p>
                      <div>
                        <p>
                          Pup Name: {item.pupNewName}
                           {/* • Breed:{" "} */}
                          {/* {item.breed?.breedInformation?.breedName} • Gender:{" "}
                          {item.gender} */}
                          <br />
                          {/* {item.dateOfBirth && (
                            <span>Birth: {item.dateOfBirth}</span>
                          )}
                          • {item.orderDate} */}
                        </p>
                        <p>
                          {/* {item.chip && <span>ID: {item.chip}</span>} */}
                          {item.weight && (
                            <span>• Weight lbs: {item.weight}</span>
                          )}
                        </p>
                        {item.PupsInstagram && (
                          <p>Pup Instagram: {item.PupsInstagram}</p>
                        )}
                      </div>
                      {item.pupEnjoyCmnt && (
                        <div className="my-4">
                          <label htmlFor="review" className="font-bold">
                            Review:
                          </label>
                          <p id="review">{item.pupEnjoyCmnt}</p>
                        </div>
                      )}
                      {item.experience && (
                        <div className="my-4">
                          <label htmlFor="feedback" className="font-bold">
                            Feedback:
                          </label>
                          <p id="feedback">{item.experience}</p>
                        </div>
                      )}
                    </div>
                    <div className="mt-2 flex space-x-2">
                      <button
                        onClick={(e) =>
                          approvedReview(e, item._id, "approve", item.approval)
                        }
                        className="rounded bg-blue-500 px-2 py-1 text-sm text-white"
                      >
                        {item.approval ? "Dis Approve" : "Approve"}
                      </button>
                      <button
                        onClick={(e) => handleDelete(e, item?._id, "delete")}
                        className="rounded bg-red-500 px-2 py-1 text-sm text-white"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => handleViewFeedback(item?._id)}
                        className="flex items-center justify-center gap-2 rounded bg-green-500 px-2 py-1 text-sm text-white"
                      >
                        View Feedback
                        <RxOpenInNewWindow />
                      </button>

                      <button
                        onClick={() => handleEditReview(item?._id)}
                        className="flex items-center justify-center gap-2 rounded bg-gray-700 px-2 py-1 text-sm text-white"
                      >
                        Edit review
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-7 grid max-h-[500px] grid-cols-4 gap-5 overflow-y-auto">
                  {item.images &&
                    item.images.map((image, imgIndex) => (
                      <div key={imgIndex}>
                        <div>
                          <img
                            src={image}
                            alt={image}
                            title={image}
                            className="h-48 w-72 rounded-2xl bg-center object-cover"
                          />
                        </div>
                        {/* <div className="mt-4 flex flex-col items-start gap-2 px-4 text-sm">
                          <div className="flex gap-4">
                            <input
                              type="checkbox"
                              placeholder="Checkboxes"
                              name="checkboxes"
                              value="Featured"
                              id="featured"
                              className="h-3 w-3 rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
                            />
                            <label htmlFor="featured">Featured</label>
                          </div>
                          <div className="flex gap-4">
                            <input
                              type="checkbox"
                              placeholder="Checkboxes"
                              name="checkboxes"
                              value="Primary"
                              id="primary"
                              className="h-3 w-3 rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
                            />
                            <label htmlFor="primary">Primary</label>
                          </div>
                        </div> */}
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <GalleryForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this review?"
            : "Are you sure you want to delete this review?"
        }
        okText={"Confirm"}
      />
    </div>
  );
};

export default TableGallery;
