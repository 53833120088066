import {
  MdBarChart,
  MdOutlineCreditCard,
  MdAutoFixHigh,
  MdGridView,
  MdHealthAndSafety,
  MdOutlineAirplanemodeActive,
  MdDraw,
  MdFontDownload,
  MdOutlinePets,
  MdBookmarkAdded,
  MdDashboard,
  MdAirplaneTicket,
  MdLocalPlay,
  MdOutlineScreenSearchDesktop,
  MdInsertPhoto,
  MdFeed,
  MdGroups,
  MdAdjust,
  MdMarkAsUnread,
  MdLibraryBooks,
  MdDirectionsRun,
  MdPrint,
  MdDocumentScanner,
  MdOutlineLocalHospital,
} from "react-icons/md";
import { FaChevronLeft, FaChevronRight, FaDog } from "react-icons/fa";
import { LuDog } from "react-icons/lu";

import { FaQuran, FaUserCircle } from "react-icons/fa";
import { MdCheck, MdEdit } from "react-icons/md";
import { LiaTimesSolid } from "react-icons/lia";
import { GoGraph } from "react-icons/go";
import { list } from "@chakra-ui/react";
import { TbDog } from "react-icons/tb";




// Left Arrow
export const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="z-1 absolute left-2 top-1/2 -translate-y-1/2 transform rounded-full bg-zinc-100 p-2 shadow hover:bg-zinc-200"
      onClick={onClick}
      style={{ zIndex: 1000 }}
    >
      <FaChevronLeft size={16} className="text-xl text-zinc-600" />
    </button>
  );
};

// Right Arrow
export const NextArrow = ({ onClick }) => {
  return (
    <button
      className="z-1 absolute right-2 top-1/2 -translate-y-1/2 transform rounded-full bg-zinc-100 p-2 shadow hover:bg-zinc-200"
      onClick={onClick}
      style={{ zIndex: 1000 }}
    >
      <FaChevronRight size={16} className="text-xl text-zinc-600" />
    </button>
  );
};


export const menuItemsSales = [
  // {
  //   menuLabel: "watchlist",
  //   icon: MdGridView,
  //   link: "watchlist",
  // },
  {
    menuLabel: "sales dashboard",
    icon: MdBarChart,
    link: "sales-dashboard",
  },
  {
    menuLabel: "orders",
    icon: MdOutlineCreditCard,
    link: "orders",
  },

  // {
  //   menuLabel: "offers",
  //   icon: MdAutoFixHigh,
  //   link: "offers",
  // },
  {
    menuLabel: "insurance",
    icon: MdHealthAndSafety,
    link: "insurance",
  },
  // {
  //   menuLabel: "deliveries",
  //   icon: MdOutlineAirplanemodeActive,
  //   link: "deliveries",
  // },
  // {
  //   menuLabel: "health services",
  //   icon: MdOutlineLocalHospital,
  //   link: "health-services",
  // },
  {
    menuLabel: "special requests",
    icon: MdDraw,
    link: "special-requests",
  },
  // {
  //   menuLabel: "reviews",
  //   icon: MdFontDownload,
  //   link: "reviews",
  // },
];

export const menuItemPups = [
  {
    menuLabel: "products",
    icon: MdDashboard,
    link: "products",
  },
  // {
  //   menuLabel: "categories",
  //   icon: MdDashboard,
  //   link: "categories",
  // },
  {
    menuLabel: "pups listing",
    icon: MdOutlinePets,
    link: "pups-listing",
  },
  {
    menuLabel: "Breed info",
    icon: LuDog,
    link: "breed-info",
  },
  {
    menuLabel: "Parent info",
    icon: FaDog,
    link: "parent-info",
  },
 
  {
    menuLabel: "Color info",
    icon: TbDog,
    link: "color-info",
  },

  
];

export const menuItemTracking = [
  {
    menuLabel: "Wholesale",
    icon: MdDocumentScanner,
    link: "wholesales",
  },
];

export const menuItemBreeder = [
  // {
  //   menuLabel: "nk dashboards",
  //   icon: GoGraph,
  //   link: "nk-dashboards",
  // },
  {
    menuLabel: "bookings",
    icon: MdBookmarkAdded,
    link: "bookings",
  },
];

export const menuItemCheckout = [
  {
    menuLabel: "delivery options",
    icon: MdAirplaneTicket,
    link: "delivery-options",
  },
  {
    menuLabel: "discounts",
    icon: MdLocalPlay,
    link: "discounts",
  },
];

export const menuItemWebsiteContent = [
  // {
  //   menuLabel: "seo dashboard",
  //   icon: MdOutlineScreenSearchDesktop,
  //   link: "seo-dashboard",
  // },
  {
    menuLabel: "Reviews",
    icon: MdInsertPhoto,
    link: "gallery",
  },

  {
    menuLabel: "faqs",
    icon: FaQuran,
    link: "faqs",
  },
  {
    menuLabel: "pages",
    icon: MdFeed,
    link: "pages",
  },
  {
    menuLabel: "teams",
    icon: MdGroups,
    link: "teams",
  },
  {
    menuLabel: "target cities",
    icon: MdAdjust,
    link: "target-cities",
  },
  {
    menuLabel: "Cavapoos 101",
    icon: FaDog,
    link: "cavaPoo101",
  },
];


export const menuItemSetting = [
  // {
  //   menuLabel: "users",
  //   icon: FaUserCircle,
  //   link: "users",
  // },
  {
    menuLabel: "Campaign",
    icon: MdMarkAsUnread,
    link: "email-sms-settings",
  },
  {
    menuLabel: "Email",
    icon: MdMarkAsUnread,
    link: "email",
  },
  // {
  //   menuLabel: "add query",
  //   icon: MdLibraryBooks,
  //   link: "add-query",
  // },
];

// export const menuItemUtilities = [
//   {
//     menuLabel: "gm",
//     icon: MdDirectionsRun,
//     link: "gross-margin",
//   },
// ];

export const tableData = [
  {
    id: 1,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 2,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 3,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 4,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 5,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 6,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 7,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 8,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
  {
    id: 9,
    icon: (
      <div className="flex cursor-pointer items-center justify-center rounded-lg border border-[#001219]/50 bg-white p-1">
        <MdPrint size={22} />
      </div>
    ),
    firstName: "John",
    lastName: "Doe",
    cellphone: "123-456-7890",
    description: "Lorem ipsum dolor sit amet",
    orderDate: "2023-06-15",
    disposition: "Pending",
    arrivalDate: "2023-06-20",
    ship: "Yes",
    offer: "10% off",
    pupPrice: "$2000",
    payment: "Credit Card",
    coupon: "SUMMER21",
    remaining: "$0",
    actions: (
      <div className="flex gap-2">
        <button className="rounded-full border border-[#001219] bg-transparent p-2">
          <MdEdit size={22} />
        </button>
        <button className="rounded-full bg-[#05CD99] p-2 text-white">
          <MdCheck size={22} />
        </button>
        <button className="rounded-full bg-[#EE5D50] p-2 text-white">
          <LiaTimesSolid size={22} />
        </button>
      </div>
    ),
  },
];

// export const breedData = [
//   {
//     image: "/images/pet1.png",
//     breedName: "breed1",
//   },
//   {
//     image: "/images/pet2.png",
//     breedName: "breed2",
//   },
//   {
//     image: "/images/pet3.png",
//     breedName: "breed3",
//   },
//   {
//     image: "/images/pet4.png",
//     breedName: "breed4",
//   },
// ];

// export const breedDetails = [
//   {
//     breedName: "Multipoo Puppy",
//     dogs: [
//       {
//         breedName: "Maltipoo Puppy",
//         dogName: "Stefano",
//         image: "/images/breed1-3.jpeg",
//         weightOunce: 7,
//         weightLbs: 3,
//         ageWeeks: 11,
//         price: 2000,
//         discount: 400,
//         priceUnits: "$",
//       },
//     ],
//   },

//   {
//     breedName: "Beagle Puppy",
//     dogs: [
//       {
//         breedName: "Beagle Puppy",
//         dogName: "Stefano",
//         image: "/images/breed1-2.jpeg",
//         weightOunce: 7,
//         weightLbs: 3,
//         ageWeeks: 11,
//         price: 2000,
//         discount: 400,
//         priceUnits: "$",
//       },
//     ],
//   },

//   {
//     breedName: "Poodle Puppy",
//     dogs: [
//       {
//         breedName: "Poodle Puppy",
//         dogName: "Stefano",
//         image: "/images/breed1-1.jpeg",
//         weightOunce: 7,
//         weightLbs: 3,
//         ageWeeks: 11,
//         price: 2000,
//         discount: 400,
//         priceUnits: "$",
//       },
//     ],
//   },

//   {
//     breedName: "Poodle Puppy",
//     dogs: [
//       {
//         breedName: "Poodle Puppy",
//         dogName: "Stefano",
//         image: "/images/breed1-1.jpeg",
//         weightOunce: 7,
//         weightLbs: 3,
//         ageWeeks: 11,
//         price: 2000,
//         discount: 400,
//         priceUnits: "$",
//       },
//     ],
//   },
// ];

export const OrderStatusData = [
  {
    title: "pending orders",
    numbers: 1520,
  },
  {
    title: "cancelled orders",
    numbers: 1520,
  },
  {
    title: "received orders",
    numbers: 1520,
  },
  {
    title: "served orders",
    numbers: 1520,
  },
  {
    title: "new customers",
    numbers: 1520,
  },
];

export const pupsListing_salesTableData = [
  {
    title: "Daily Sales",
    header1: "Date",
    header2: "Sales",
    data: [
      {
        dates: "2024-05-07",
        sales: "03",
      },
      {
        dates: "2024-05-07",
        sales: "03",
      },
      {
        dates: "2024-05-07",
        sales: "03",
      },
      {
        dates: "2024-05-07",
        sales: "03",
      },
    ],
  },
  {
    title: "Breed Sales",
    header1: "Breed",
    header2: "Sales",
    data: [
      {
        breed: "Cavapoo",
        sales: "03",
      },
      {
        breed: "Cavapoo",
        sales: "03",
      },
      {
        breed: "Cavapoo",
        sales: "03",
      },
      {
        breed: "Cavapoo",
        sales: "03",
      },
    ],
  },
  {
    title: "State Sales",
    header1: "State",
    header2: "Sales",
    data: [
      {
        state: "Sc",
        sales: "03",
      },
      {
        state: "NC",
        sales: "03",
      },
      {
        state: "GA",
        sales: "03",
      },
      {
        state: "TN",
        sales: "03",
      },
    ],
  },
  {
    title: "Store Sales",
    header1: "Store",
    header2: "Sales",
    data: [
      {
        store: "Simply Cavapoos",
        sales: "40",
      },
    ],
  },
  {
    title: "Survey Referral",
    header1: "Referrer",
    header2: "Sales",
    data: [
      {
        referrer: "Google Search",
        sales: "26",
      },
      {
        referrer: "Social Media",
        sales: "10",
      },
      {
        referrer: "Referral",
        sales: "01",
      },
    ],
  },
  {
    title: "Survey Browsing Time",
    header1: "Survey Time",
    header2: "Sales",
    data: [
      {
        state: "3+ Months",
        sales: "05",
      },
      {
        state: "2-4 Weeks",
        sales: "04",
      },
      {
        state: "1-3 Months",
        sales: "03",
      },
      {
        state: "Less than a week",
        sales: "03",
      },
    ],
  },
];
export const pupsListing_tableData = [
  {
    title: "Breed / Gender",
    tableHead: ["Breed", "M", "F", "Qty", "Avg $"],
    data: [
      {
        breed: "Cavapoo",
        male: "03",
        female: "03",
        qty: "03",
        sales: "$1,845",
      },
      {
        breed: "Maltipoo",
        male: "03",
        female: "03",
        qty: "03",
        sales: "$1,845",
      },
      {
        breed: "Cavapoo",
        male: "03",
        female: "03",
        qty: "03",
        sales: "$1,845",
      },
      {
        breed: "Cavapoo",
        male: "03",
        female: "03",
        qty: "03",
        sales: "$1,845",
      },
    ],
  },
  // {
  //   title: "Breed / Acquisition",
  //   tableHead: ["Breed", "-4", "2", "3", "4", "5", "Total"],
  //   data: [
  //     {
  //       breed: "Cavapoo",
  //       acq1: "03",
  //       acq2: "03",
  //       acq3: "03",
  //       acq4: "03",
  //       acq5: "03",
  //       total: "03",
  //     },
  //     {
  //       breed: "Cavapoo",
  //       acq1: "03",
  //       acq2: "03",
  //       acq3: "03",
  //       acq4: "03",
  //       acq5: "03",
  //       total: "03",
  //     },
  //   ],
  // },
  // {
  //   title: "Ltr",
  //   tableHead: ["LTR", "QTY"],

  //   data: [
  //     {
  //       E: "E",
  //       sales: "03",
  //     },
  //     {
  //       K: "K",
  //       sales: "03",
  //     },
  //     {
  //       W: "W",
  //       sales: "03",
  //     },
  //     {
  //       Z: "Z",
  //       sales: "03",
  //     },
  //   ],
  // },
  // {
  //   title: "UnSold / Acquisition",
  //   tableHead: ["Name", "ID4", "Loc"],

  //   data: [
  //     {
  //       name: " Simply Cavapoos",
  //       id: "03",
  //       location: "North",
  //     },
  //   ],
  // },
];

export const availablePups_tableData = [
  {
    imgSrc: "/images/breed1-3.jpeg",
    available: "07/17/2024",
    details:
      "Beahs Puppies • 10A\nWallace • 0046 • French Bulldog\nMale • AKC • 6lbs 0oz @ 9 weeks\n05/05/2024 • ChocolateMerle • 26-30 lb\n$6,665",
    views: "0 Views\n0 / 0 Offers\n0 / 0 Requests",
    checkboxes: "Test",
    desc: "H1: Fluffy French Bulldog\nDESC: *Please be advised puppies eye color can change. Puppies don't reach their mature eye color, until 9 to 16 weeks old. The timeline depends on when the pigment melanin has fully developed. Some puppies keep blue eyes throughout their entire life, but others eye(s) could fully or partially change to green or brown eye(s).\nINFO:\nOVERRIDE: 07/17/2024",
    wholesale: "WHOLESALE PRICE:\nPURCHASER:\nQTY: 1 WHOLESALE DATE:",
  },
  {
    imgSrc: "/images/breed1-3.jpeg",
    available: "07/17/2024",
    details:
      "Beahs Puppies • 10A\nWallace • 0046 • French Bulldog\nMale • AKC • 6lbs 0oz @ 9 weeks\n05/05/2024 • ChocolateMerle • 26-30 lb\n$6,665",
    views: "0 Views\n0 / 0 Offers\n0 / 0 Requests",
    checkboxes: "Checkboxes",
    desc: "H1: Fluffy French Bulldog\nDESC: *Please be advised puppies eye color can change. Puppies don't reach their mature eye color, until 9 to 16 weeks old. The timeline depends on when the pigment melanin has fully developed. Some puppies keep blue eyes throughout their entire life, but others eye(s) could fully or partially change to green or brown eye(s).\nINFO:\nOVERRIDE: 07/17/2024",
    wholesale: "WHOLESALE PRICE:\nPURCHASER:\nQTY: 1 WHOLESALE DATE:",
  },
  {
    imgSrc: "/images/breed1-3.jpeg",
    available: "07/17/2024",
    details:
      "Beahs Puppies • 10A\nWallace • 0046 • French Bulldog\nMale • AKC • 6lbs 0oz @ 9 weeks\n05/05/2024 • ChocolateMerle • 26-30 lb\n$6,665",
    views: "0 Views\n0 / 0 Offers\n0 / 0 Requests",
    checkboxes: "Checkboxes",
    desc: "H1: Fluffy French Bulldog\nDESC: *Please be advised puppies eye color can change. Puppies don't reach their mature eye color, until 9 to 16 weeks old. The timeline depends on when the pigment melanin has fully developed. Some puppies keep blue eyes throughout their entire life, but others eye(s) could fully or partially change to green or brown eye(s).\nINFO:\nOVERRIDE: 07/17/2024",
    wholesale: "WHOLESALE PRICE:\nPURCHASER:\nQTY: 1 WHOLESALE DATE:",
  },
];

export const gallery_tableData = [
  {
    pupDetails: [
      {
        name: "Teigan",
        gender: "Male",
        pupId: "519365",
        breed: "Havapoo",
        birthDate: "4/29/2024",
        time: "4:00:00 AM",
        pupInstagram: "taigan",
        adultWeight: "46lbs",
      },
    ],
    ownerDetails: [
      {
        name: "NINJA",
        email: "kingsdaughter.eagle@gmail.com",
        gender: "Female",
        cellPhone: "+478-2354434",
      },
    ],
    review:
      "My experience with  Simply Cavapoos was amazing from start to finish.  They were thorough with details and made me at ease with concerns that I had before and after adoption.  I Highly recommend them to anyone desiring a fur baby!",
    pupFeedback: "Getting a bath, playing with his toys and fetching!!",
    images: [
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
    ],
  },
  {
    pupDetails: [
      {
        name: "Teigan",
        gender: "Male",
        pupId: "519365",
        breed: "Havapoo",
        birthDate: "4/29/2024",
        time: "4:00:00 AM",
        pupInstagram: "taigan",
        adultWeight: "46lbs",
      },
    ],
    ownerDetails: [
      {
        name: "NINJA",
        email: "kingsdaughter.eagle@gmail.com",
        gender: "Female",
        cellPhone: "+478-2354434",
      },
    ],
    review:
      "My experience with Simply Southern Pups was amazing from start to finish.  They were thorough with details and made me at ease with concerns that I had before and after adoption.  I Highly recommend them to anyone desiring a fur baby!",
    pupFeedback: "Getting a bath, playing with his toys and fetching!!",
    images: [
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
    ],
  },
  {
    pupDetails: [
      {
        name: "Teigan",
        gender: "Male",
        pupId: "519365",
        breed: "Havapoo",
        birthDate: "4/29/2024",
        time: "4:00:00 AM",
        pupInstagram: "taigan",
        adultWeight: "46lbs",
      },
    ],
    ownerDetails: [
      {
        name: "NINJA",
        email: "kingsdaughter.eagle@gmail.com",
        gender: "Female",
        cellPhone: "+478-2354434",
      },
    ],
    review:
      "My experience with  Simply Cavapoos was amazing from start to finish.  They were thorough with details and made me at ease with concerns that I had before and after adoption.  I Highly recommend them to anyone desiring a fur baby!",
    pupFeedback: "Getting a bath, playing with his toys and fetching!!",
    images: [
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
    ],
  },
  {
    pupDetails: [
      {
        name: "Teigan",
        gender: "Male",
        pupId: "519365",
        breed: "Havapoo",
        birthDate: "4/29/2024",
        time: "4:00:00 AM",
        pupInstagram: "taigan",
        adultWeight: "46lbs",
      },
    ],
    ownerDetails: [
      {
        name: "NINJA",
        email: "kingsdaughter.eagle@gmail.com",
        gender: "Female",
        cellPhone: "+478-2354434",
      },
    ],
    review:
      "My experience with Simply Southern Pups was amazing from start to finish.  They were thorough with details and made me at ease with concerns that I had before and after adoption.  I Highly recommend them to anyone desiring a fur baby!",
    pupFeedback: "Getting a bath, playing with his toys and fetching!!",
    images: [
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
      // "/images/breed1-3.jpeg",
    ],
  },
];

export const pup_reviews = [
  {
    _id: "1",
    pup_new_name: "MALTIPOO PUPPY",
    pup_name: "Pomerenian Puppy",
    breed: "Cannoli",
    city: "Greensboro",
    state: "NC",
    customer: "S.H",
    review:
      "I had a wonderful experience with Simply Southern Pups! They allowed me to come initially just for a visit to meet and play with a toy pom. I fell in love immediately and they were flexible to work around my schedule to come back and pick her up",
  },
  {
    _id: "2",
    pup_new_name: "MALTIPOO PUPPY",
    pup_name: "Pomerenian Puppy",
    breed: "Cannoli",
    city: "Greensboro",
    state: "NC",
    customer: "S.H",
    review:
      "I had a wonderful experience with Simply Southern Pups! They allowed me to come initially just for a visit to meet and play with a toy pom. I fell in love immediately and they were flexible to work around my schedule to come back and pick her up",
  },
  {
    _id: "3",
    pup_new_name: "MALTIPOO PUPPY",
    pup_name: "Pomerenian Puppy",
    breed: "Cannoli",
    city: "Greensboro",
    state: "NC",
    customer: "S.H",
    review:
      "I had a wonderful experience with Simply Southern Pups! They allowed me to come initially just for a visit to meet and play with a toy pom. I fell in love immediately and they were flexible to work around my schedule to come back and pick her up",
  },
  {
    _id: "4",
    pup_new_name: "MALTIPOO PUPPY",
    pup_name: "Pomerenian Puppy",
    breed: "Cannoli",
    city: "Greensboro",
    state: "NC",
    customer: "S.H",
    review:
      "I had a wonderful experience with Simply Southern Pups! They allowed me to come initially just for a visit to meet and play with a toy pom. I fell in love immediately and they were flexible to work around my schedule to come back and pick her up",
  },
  {
    _id: "5",
    pup_new_name: "MALTIPOO PUPPY",
    pup_name: "Pomerenian Puppy",
    breed: "Cannoli",
    city: "Greensboro",
    state: "NC",
    customer: "S.H",
    review:
      "I had a wonderful experience with Simply Southern Pups! They allowed me to come initially just for a visit to meet and play with a toy pom. I fell in love immediately and they were flexible to work around my schedule to come back and pick her up",
  },
  {
    _id: "6",
    pup_new_name: "MALTIPOO PUPPY",
    pup_name: "Pomerenian Puppy",
    breed: "Cannoli",
    city: "Greensboro",
    state: "NC",
    customer: "S.H",
    review:
      "I had a wonderful experience with Simply Southern Pups! They allowed me to come initially just for a visit to meet and play with a toy pom. I fell in love immediately and they were flexible to work around my schedule to come back and pick her up",
  },
];

export const contact_info = [
  {
    heading1: "PUPPY",
    heading2: "AGENTS",
    cellPhone: "803-597-1567",
    email: "reserve@simplycavapoos.com",
    heading3: "when to contact",
    list: [
      "Ask questions about how-to adopt a puppy",
      "Schedule a time to pick up your puppy",
      "Get additional info about a puppy of interest",
      "Discuss details about your order",
      "Add additional items to your order",
      "Ask about financing options",
    ],
  },
  // {
  //   heading1: "PUPPY",
  //   heading2: "CARE",
  //   cellPhone: "803-830-6109",
  //   email: "reserve@simplycavapoos.com",
  //   heading3: "when to contact",
  //   list: [
  //     "Callback from one of our puppy check calls",
  //     "Ask feeding & care questions",
  //     "Ask any questions after receiving your puppy",
  //     "Health guarantee questions & claims",
  //   ],
  // },
  // {
  //   heading1: "PUPPY",
  //   heading2: "CARE",
  //   cellPhone: "803-830-6109",
  //   email: "reserve@simplycavapoos.com",
  //   heading3: "when to contact",
  //   list: [],
  // },
  // {
  //   heading1: "PUPPY",
  //   heading2: "CARE",
  //   cellPhone: "803-830-6109",
  //   email: "reserve@simplycavapoos.com",
  //   heading3: "when to contact",
  //   list: [],
  // },
];

export const breed_images = [
  {
    title: "breed-1",
    image: "/images/pet1.png",
  },
  {
    title: "breed-2",
    image: "/images/pet2.png",
  },
  {
    title: "breed-3",
    image: "/images/pet3.png",
  },
  {
    title: "breed-3",
    image: "/images/pet3.png",
  },
  {
    title: "breed-4",
    image: "/images/pet4.png",
  },
  {
    title: "breed-5",
    image: "/images/pet5.png",
  },
  {
    title: "breed-6",
    image: "/images/pet6.png",
  },
  {
    title: "breed-7",
    image: "/images/pet7.png",
  },
  {
    title: "breed-8",
    image: "/images/pet8.png",
  },
];

export const customer_reviews = [
  {
    customerName: "Adam bale",
    puppy: "Poodle puppy in florence",
    review:
      "Lorem ipsum dolor sit amet consectetur. Mauris ultricies sit lacus tristique. Et enim maecenas morbi felis erat. Aliquam dignissim viverra faucibus egestas diam. Dui diam suspendisse urna lacus ultrices odio",
    image: "/images/customer-review-img-1.jpeg",
  },
  {
    customerName: "luna",
    puppy: "Poodle puppy in florence",
    review:
      "Lorem ipsum dolor sit amet consectetur. Mauris ultricies sit lacus tristique. Et enim maecenas morbi felis erat. Aliquam dignissim viverra faucibus egestas diam. Dui diam suspendisse urna lacus ultrices odio",
    image: "/images/customer-review-img-2.png",
  },
  {
    customerName: "luna",
    puppy: "Poodle puppy in florence",
    review:
      "Lorem ipsum dolor sit amet consectetur. Mauris ultricies sit lacus tristique. Et enim maecenas morbi felis erat. Aliquam dignissim viverra faucibus egestas diam. Dui diam suspendisse urna lacus ultrices odio",
    image: "/images/customer-review-img-3.png",
  },
  {
    customerName: "Adam bale",
    puppy: "Poodle puppy in florence",
    review:
      "Lorem ipsum dolor sit amet consectetur. Mauris ultricies sit lacus tristique. Et enim maecenas morbi felis erat. Aliquam dignissim viverra faucibus egestas diam. Dui diam suspendisse urna lacus ultrices odio",
    image: "/images/customer-review-img-4.png",
  },
];

//important
export const breederOptions = [
  { label: "Acorn Acres", value: "Acorn Acres" },
  { label: "Bark Creek Bliss", value: "Bark Creek Bliss" },
  { label: "Charming Creations", value: "Charming Creations" },
  { label: "Country Charm", value: "Country Charm" },
  { label: "Country Comfort", value: "Country Comfort" },
  { label: "Country Meadows", value: "Country Meadows" },
  { label: "Cozy Creek Charm", value: "Cozy Creek Charm" },
  { label: "Creekside Meadows", value: "Creekside Meadows" },
  { label: "Deer Creek", value: "Deer Creek" },
  { label: "Doodle Darlings", value: "Doodle Darlings" },
  { label: "Doodle Dynasty", value: "Doodle Dynasty" },
  { label: "Edgewater Farms", value: "Edgewater Farms" },
  { label: "Emerald Hills", value: "Emerald Hills" },
  { label: "Evergreen Whispers", value: "Evergreen Whispers" },
  { label: "Firefly Lane", value: "Firefly Lane" },
  { label: "Fox Fields", value: "Fox Fields" },
  { label: "FreeBark Oasis", value: "FreeBark Oasis" },
  { label: "Hazelwood Hills", value: "Hazelwood Hills" },
  { label: "Honey Lane", value: "Honey Lane" },
  { label: "Lanes Little Gems", value: "Lanes Little Gems" },
  { label: "Lucky Springs", value: "Lucky Springs" },
  { label: "Magnolia Valley", value: "Magnolia Valley" },
  { label: "Marion Miller", value: "Marion Miller" },
  { label: "Northstar Springs", value: "Northstar Springs" },
  { label: "Oakley Hill", value: "Oakley Hill" },
  { label: "Owl Ridge", value: "Owl Ridge" },
  { label: "Pawfect Acres", value: "Pawfect Acres" },
  { label: "Peak Paws Point", value: "Peak Paws Point" },
  { label: "Silvergate Ranch", value: "Silvergate Ranch" },
  { label: "Snicker Doodle Farms", value: "Snicker Doodle Farms" },
  { label: "Summer Breeze", value: "Summer Breeze" },
  { label: "Sunberry Valley", value: "Sunberry Valley" },
  { label: "Sunnydoodles", value: "Sunnydoodles" },
  { label: "Sunnyside Point", value: "Sunnyside Point" },
  { label: "Waggin Country", value: "Waggin Country" },
  { label: "Wildwood Acres", value: "Wildwood Acres" },
  { label: "Woodford Ridge", value: "Woodford Ridge" },
];

export const stateTerritory = [
  { state: "Alabama", abbreviation: "AL" },
  { state: "Alaska", abbreviation: "AK" },
  { state: "Arizona", abbreviation: "AZ" },
  { state: "Arkansas", abbreviation: "AR" },
  { state: "California", abbreviation: "CA" },
  { state: "Colorado", abbreviation: "CO" },
  { state: "Connecticut", abbreviation: "CT" },
  { state: "Delaware", abbreviation: "DE" },
  { state: "District of Columbia", abbreviation: "DC" },
  { state: "Florida", abbreviation: "FL" },
  { state: "Georgia", abbreviation: "GA" },
  { state: "Hawaii", abbreviation: "HI" },
  { state: "Idaho", abbreviation: "ID" },
  { state: "Illinois", abbreviation: "IL" },
  { state: "Indiana", abbreviation: "IN" },
  { state: "Iowa", abbreviation: "IA" },
  { state: "Kansas", abbreviation: "KS" },
  { state: "Kentucky", abbreviation: "KY" },
  { state: "Louisiana", abbreviation: "LA" },
  { state: "Maine", abbreviation: "ME" },
  { state: "Maryland", abbreviation: "MD" },
  { state: "Massachusetts", abbreviation: "MA" },
  { state: "Michigan", abbreviation: "MI" },
  { state: "Minnesota", abbreviation: "MN" },
  { state: "Mississippi", abbreviation: "MS" },
  { state: "Missouri", abbreviation: "MO" },
  { state: "Montana", abbreviation: "MT" },
  { state: "Nebraska", abbreviation: "NE" },
  { state: "Nevada", abbreviation: "NV" },
  { state: "New Hampshire", abbreviation: "NH" },
  { state: "New Jersey", abbreviation: "NJ" },
  { state: "New Mexico", abbreviation: "NM" },
  { state: "New York", abbreviation: "NY" },
  { state: "North Carolina", abbreviation: "NC" },
  { state: "North Dakota", abbreviation: "ND" },
  { state: "Northern Mariana Islands", abbreviation: "MP" },
  { state: "Ohio", abbreviation: "OH" },
  { state: "Oklahoma", abbreviation: "OK" },
  { state: "Oregon", abbreviation: "OR" },
  { state: "Pennsylvania", abbreviation: "PA" },
  { state: "Puerto Rico", abbreviation: "PR" },
  { state: "Rhode Island", abbreviation: "RI" },
  { state: "South Carolina", abbreviation: "SC" },
  { state: "South Dakota", abbreviation: "SD" },
  { state: "Tennessee", abbreviation: "TN" },
  { state: "Texas", abbreviation: "TX" },
  { state: "Trust Territories", abbreviation: "TT" },
  { state: "Utah", abbreviation: "UT" },
  { state: "Vermont", abbreviation: "VT" },
  { state: "Virginia", abbreviation: "VA" },
  { state: "Virgin Islands", abbreviation: "VI" },
  { state: "Washington", abbreviation: "WA" },
  { state: "West Virginia", abbreviation: "WV" },
  { state: "Wisconsin", abbreviation: "WI" },
  { state: "Wyoming", abbreviation: "WY" },
];

//important
export const shippingOptions = [
  {
    title: "airline shipping option",
    description:
      "Although undemanding to manage, the Cavalier King Charles spaniel’s luxurious coat merits a little more attention to be kept in its shiny and silky appearance. A brushing or combing every other day and a monthly bath are recommended. To keep their nails from over-growing, it is best to trim them after every bath. Their ears should be checked on a regular basis, preferably weekly, and their teeth should be brushed every couple of days, if not daily. A trip to the groomer for a professional dental cleaning is also recommended every few months.",
  },
  {
    title: "ground delivery options",
    description:
      "Cavalier King Charles spaniels have moderate exercise needs. Although they are energetic and driven towards a life of adventures at times, they don’t require an intense daily exercise routine. One or two walks every day and some active play sessions indoors that can stimulate both their minds and bodies are recommended. Trips to the dog park as well as hikes and long walks should also be part of their weekly routines.",
  },
  {
    title: "premium uin person delivery options",
    description:
      "The Cavalier King Charles spaniel is not prone to as many health concerns as most small dog breeds. Among the ailments that are common in Cavalier spaniels are cataracts, allergies, weight gain, ear infections, and dental issues.",
  },
];

//important
export const footerInfo = [
  {
    title: "Contact Info",
    items: [
      {
        // label: "Payment Agents",
        // value: "803-830-6109",
        link: "tel:803-830-6109",
        type: "phone",
      },
      {
        label: "Puppy Agents",
        value: "803-597-1567",
        link: "tel:803-597-1567",
        type: "phone",
      },
      {
        label: "Our Address",
        value: "187 Conifer Drive Prosperity SC 29127",
        link: "https://maps.google.com/?q=187+Conifer+Drive+Prosperity+SC+29127",
        type: "address",
      },
    ],
  },
  {
    title: "Simply Cavapoos",
    items: [
      { label: "Available Puppy Listing", link: "/puppies" },
      { label: "Customer Reviews", link: "/customer-photos" },
      { label: "Puppy Photo Gallery", link: "/customer-photos" },
      { label: "Our Team", link: "/teams" },
    ],
  },
  {
    title: "Learn About Our Colors",
    items: [{ label: "Color Information", link: "/puppies" }],
  },
  {
    title: "Puppy Health",
    items: [
      { label: "10 Year Health Guarantee", link: "/health-guarantee" },
      { label: "Pet Insurance", link: "/pet-insurance" },
    ],
  },
];

//important
export const teams = [
  {
    img: "/images/team-img1.png",
    name: "Jason & Kendra",
    designation: "Club Creators and Owners",
    details:
      "With close to 35 years in breeding experience between the both of them, Jason and Kendra strive to provide the healthiest, most genetically sound hypoallergenic small breeds. Indicated by SSP's 10 year warranty and partnership with Fetch Insurance. Jason & Kendra look forward to speaking with you! Their hobbies include: Everything Dog, Baseball (Jason), foodies (both), & traveling (both).",
    favoriteBreeds: ["Yorkshire Terrier", "Mini Bernedoodles", "Cavapoo"],
  },
  {
    img: "/images/team-img2.webp",
    name: "Christian & Ally ",
    designation: "Kennel Club Managers & Social Media Team",
    details:
      "Christian & Ally currently own 4 lovable rescued Staffies (Milo, Axel, Stella, & Izzy) who are their world. Ally has experience working at a Doggie Daycare and Christian loves to sneak 'his' dogs treats when Ally's not looking. Their hobbies include: guns, cars & guitars.",
    favoriteBreeds: ["Staffordshire Terrier", "Yorkshire Terrier", "Shih Tzu"],
  },
  {
    img: "/images/team-img3.webp",
    name: "Scott & Lily",
    designation: "Breed Match Concierge",
    details:
      "Our newest members were the missing puzzle pieces. Scott & Lily act as the first point of contact between our puppy guests and our dog club. They are tasked with answering puppy guest inquiries, directing phone calls, coordinating travel plans, and more. Scott comes to our team with countless years of business experience & phone etiquette. We truly are blessed to have the best team in the south! Favorite Hobbies: Golf (18 and putt putt :), College Football, and his son Adam.",
    favoriteBreeds: ["Yorkshire Terrier", "Cavapoo & Mini Bernedoodle"],
  },
];

//important
// export const cavapoo_101 =
// {
//   dogTypes: {
//     title: "Understanding Dog Types",
//     detail: "Dogs have been bred for various purposes throughout history, leading to distinct categories based on their natural abilities and traits. Here are some of the most common dog types:",
//     heading: ["Herding Dogs", "Guardianship Dogs", "Sporting Dogs", "Hunting Dogs"],
//     description: [
//       "Known for their intelligence and ability to control the movement of other animals. Examples include Border Collies and Australian Shepherds.",
//       "Bred to protect property and livestock, these dogs are loyal, strong, and protective. Breeds like Rottweilers and Great Pyrenees fall into this category.",
//       "These dogs are bred for activities like hunting and retrieving. Examples include Labrador Retrievers and Spaniels.",
//       "Specially trained to track, chase, and hunt game, with breeds like Beagles and Foxhounds excelling in this area.",
//     ],
//   },
//   companionshipBreeds: {
//     title: "Companionship Breeds",
//     description: "While many dogs are bred for specific jobs, some are bred primarily for companionship, providing love, affection, and emotional support to their owners. Companionship breeds are known for their friendly, loyal, and affectionate nature, making them perfect family pets.",

//   }
// }

export const cavapoo101Content = {
  understandingDogTypes: {
    title: "Understanding Dog Types",
    description:
      "Dogs have been bred for various purposes throughout history, leading to distinct categories based on their natural abilities and traits. Here are some of the most common dog types:",
    types: [
      {
        type: "Herding Dogs",
        description:
          "Known for their intelligence and ability to control the movement of other animals. Examples include Border Collies and Australian Shepherds.",
      },
      {
        type: "Guardianship Dogs",
        description:
          "Bred to protect property and livestock, these dogs are loyal, strong, and protective. Breeds like Rottweilers and Great Pyrenees fall into this category.",
      },
      {
        type: "Sporting Dogs",
        description:
          "These dogs are bred for activities like hunting and retrieving. Examples include Labrador Retrievers and Spaniels.",
      },
      {
        type: "Hunting Dogs",
        description:
          "Specially trained to track, chase, and hunt game, with breeds like Beagles and Foxhounds excelling in this area.",
      },
    ],
  },
  companionshipBreeds: {
    title: "Companionship Breeds",
    description:
      "While many dogs are bred for specific jobs, some are bred primarily for companionship, providing love, affection, and emotional support to their owners. Companionship breeds are known for their friendly, loyal, and affectionate nature, making them perfect family pets.",
  },
  cavapoo: {
    title: "The Cavapoo: The #1 Companionship Breed",
    description:
      "Among all companionship breeds, the Cavapoo stands out as one of the most popular and beloved. A cross between the Cavalier King Charles Spaniel and a Poodle, the Cavapoo has the perfect combination of intelligence, gentleness, and a hypoallergenic coat, making it the ideal pet for many households.",
    reasons: [
      {
        title: "Affectionate and Loving",
        description:
          "Cavapoos are known for their sweet, friendly nature. They thrive on human interaction and are excellent with children, making them a great choice for families.",
      },
      {
        title: "Intelligent and Easy to Train",
        description:
          "Thanks to their Poodle lineage, Cavapoos are highly intelligent and eager to please, which makes them relatively easy to train.",
      },
      {
        title: "Hypoallergenic Coat",
        description:
          "Cavapoos often inherit the Poodle's low-shedding coat, which is ideal for people with allergies.",
      },
      {
        title: "Adaptable",
        description:
          "Whether you live in a small apartment or a house with a yard, the Cavapoo can adapt to different living environments as long as they receive the love and attention they need.",
      },
      {
        title: "Low to Moderate Energy",
        description:
          "They are active but not overly hyper, making them easy to manage in both city and suburban environments.",
      },
    ],
  },
  cavapooHQ: {
    title: "Cavapoo HQ: Resources for Cavapoo Enthusiasts",
    description:
      "To further explore why the Cavapoo is such a special breed, we've compiled a selection of resources:",
    resources: {
      videos: [
        {
          title: "Cavapoo Playtime",
          url: "https://www.youtube.com/embed/aj2tBUFpAS4?si=AneMc4FGxz1duRhT",
        },
        {
          title: "Cavapoos with Families",
          url: "https://youtube.com/embed/nQleeVLpdr0?si=4ADmpXOZc-753xSI",
        },
        {
          title: "Cavapoos with Families",
          url: "https://youtube.com/embed/nQleeVLpdr0?si=4ADmpXOZc-753xSI",
        },
        {
          title: "Cavapoos with Families",
          url: "https://youtube.com/embed/nQleeVLpdr0?si=4ADmpXOZc-753xSI",
        },
        {
          title: "Cavapoos with Families",
          url: "https://youtube.com/embed/nQleeVLpdr0?si=4ADmpXOZc-753xSI",
        },
        {
          title: "Cavapoos with Families",
          url: "https://youtube.com/embed/nQleeVLpdr0?si=4ADmpXOZc-753xSI",
        },
      ],
      websites: [
        { title: "Cavapoo Love", url: "https://cavapoolove.com" },
        { title: "Everything Cavapoo", url: "https://everythingcavapoo.com" },
      ],
      others: [
        { title: "Cavapoo Blog", url: "https://cavapooblog.com" },
        { title: "Cavapoo Forums", url: "https://cavapooforums.com" },
      ],
    },
  },
};

//important
export const breeds = [
  {
    image: "/images/merleWhite (1).jpg",
    label: "Merle",
  },
  {
    image: "/images/redWhite.jpg",
    label: "Red",
  },
  {
    image: "/images/blenhiemWhite.jpg",
    label: "Blenheim",
  },
  {
    image: "/images/merleWhite (4).jpg",
    label: "Red & White",
  },
  {
    image: "/images/merleWhite (5).jpg",
    label: "Apricot",
  },
  {
    image: "/images/merleWhite (6).jpg",
    label: "Black & Tan",
  },
  {
    image: "/images/merleWhite (7).jpg",
    label: "Black",
  },
];
