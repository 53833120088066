import React from "react";
import BreederFooterInfo from "../components/BreederFooterInfo";
import { useAllPages } from "../features/Page/pageServices";
import { useAllTEAMS } from "../features/Teams/teamsService";
// import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Arrow icons
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";


const Teams = () => {
  const { data: page, error, isLoading } = useAllPages("/api/pages");
  const { data: teams, teamError, teamIsLoading } = useAllTEAMS("/api/teams");

  return (
    <div className="poppins-regular relative w-full ">
      <div
        className="absolute left-0 top-0 h-[650px] w-full bg-cover bg-center bg-no-repeat "
        style={{ backgroundImage: "url('/images/top-banner-contact.png')" }}
      ></div>
      <div className="relative z-10 flex w-full flex-col items-center justify-center px-4 pt-40 md:px-32">
        <h6 className="text-3xl md:text-5xl">Our Team</h6>
        <h3 className="my-7 text-2xl font-bold">MEET OUR FAMILY</h3>
        <div className="my-12 mt-1 flex w-full max-w-5xl flex-col">
          {teams &&
            teams.map((team) => (
              <div
                key={team._id}
                className="my-6 grid grid-cols-1 gap-8 md:grid-cols-2"
              >
                {/* <div className="relative">
                  <Slider {...settings}>
                    {team.media.map((image, index) => (
                      <div
                        key={index}
                        className="h-[400px] w-full md:h-[620px] bg-center"
                      >
                        <img
                          src={image}
                          alt={`team-${team.name}-${index}`}
                          className="h-[90%] w-[90%] rounded-3xl object-cover bg-center"
                        />
                      </div>
                    ))}
                  </Slider>
                </div> */}

                <div className="relative">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{ delay: 6000 }}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    pagination={{ clickable: true }}
                    className="swiper-container"
                  >
                    {team.media.map((image, index) => (
                      <SwiperSlide key={index}>
                        <div className="flex h-[400px] cursor-grab items-center justify-center md:h-[620px]">
                          <img
                            src={image}
                            alt={`team-${team.name}-${index}`}
                            className="h-full w-full rounded-3xl object-cover"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className="swiper-button-prev absolute left-3 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-black/50 p-2 text-white">
                    <FaChevronLeft size={14} />
                  </div>
                  <div className="swiper-button-next absolute right-3 top-1/2 z-10 -translate-y-1/2 transform rounded-full bg-black/50 p-2 text-white">
                    <FaChevronRight size={14} />
                  </div>
                  <div className="swiper-pagination"></div>
                </div>

                {/* Content Section */}
                <div className="flex w-full flex-col justify-around space-y-4 md:w-auto md:max-w-[500px]">
                  {/* Name and Designation */}
                  <div>
                    <p className="text-2xl font-semibold">{team?.name}</p>
                    <p>{team?.title}</p>
                  </div>

                  {/* Details */}
                  <div>
                    <p>{team?.description}</p>
                  </div>

                  {/* Favorite Breeds */}
                  {team?.favoriteBreed?.length > 0 && (
                    <div>
                      <p className="font-semibold">
                        Favorite Breeds <br />
                        <span className="font-normal">
                          {team.favoriteBreed?.map((breeds, index) => (
                            <span key={breeds}>
                              {breeds}
                              {index < team?.favoriteBreed?.length - 1 && " | "}
                            </span>
                          ))}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <BreederFooterInfo />
      </div>
    </div>
  );
};

export default Teams;
