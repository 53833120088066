import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";

import RealBreedForm from "./components/RealBreedForm";
import TableRealBreed from "./components/TableRealBreed";
import { GlobalModalV1 } from "../../components/GlobalModal";


const RealBreedInfo = () => {

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <Tabs>
          <TabList>
            <Tab>Add Color</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Breed />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default RealBreedInfo;

const Breed = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className="my-4 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        Add Color
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <RealBreedForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <TableRealBreed />
    </>
  );
};
