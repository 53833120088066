import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Select,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stateTerritory } from "../utils/data";
import {
  setFormData,
  setIsStep3Done,
  setIsStep2Done,
  setCurrentStep,
} from "../features/buy-now/action";

import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup.string().required("Phone is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  licenseNumber: yup.string().required("Driver's license number is required"),
  instagram: yup.string(),
  address: yup.string().required("Address is required"),
  sameAsDestination: yup.boolean(),
  billingFirstName: yup.string().when("sameAsDestination", {
    is: false,
    then: (schema) => schema.required("Billing first name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  billingLastName: yup.string().when("sameAsDestination", {
    is: false,
    then: (schema) => schema.required("Billing last name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  billingCity: yup.string().when("sameAsDestination", {
    is: false,
    then: (schema) => schema.required("Billing city is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  billingState: yup.string().when("sameAsDestination", {
    is: false,
    then: (schema) => schema.required("Billing state is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const EnterInformation = () => {
  const dispatch = useDispatch();
  const { formData, addedProducts, currentStep } = useSelector(
    (state) => state.buyNow,
  );

  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData,
  });

  const watchBillingCheckbox = watch("sameAsDestination");
;

  useEffect(() => {
    dispatch(setIsStep2Done(true));
    if (watchBillingCheckbox) {
      setValue("billingFirstName", "");
      setValue("billingLastName", "");
      setValue("billingCity", "");
      setValue("billingState", "");
    }
  }, [watchBillingCheckbox, setValue, dispatch]);

  const calculateOrderSummary = (products) => {
    const subtotal = products.reduce(
      (sum, product) => sum + (product.price || 0),
      0,
    );
    return {
      subtotal,
      total: subtotal, // Assuming no tax or shipping for now
    };
  };

  const onSubmit = (data) => {
    const selectedProducts = formData.products.filter(
      (item) => addedProducts.includes(item._id) || item.required,
    );

    const orderSummary = calculateOrderSummary(selectedProducts);


    const formattedData = {
      ...data,
      contactInformation: {
        emailAddress: data.email,
        phone: data.phone,
      },
      destinationAddress: {
        firstName: data.firstName,
        lastName: data.lastName,
        city: data.city,
        state: data.state,
        address: data.address,
      },
      billingAddress: data.sameAsDestination
        ? {
            firstName: data.firstName,
            lastName: data.lastName,
            city: data.city,
            state: data.state,
          }
        : {
            firstName: data.billingFirstName,
            lastName: data.billingLastName,
            city: data.billingCity,
            state: data.billingState,
          },
      products: selectedProducts,
      orderSummary: orderSummary,
    };


    dispatch(setFormData(formattedData));
    dispatch(setIsStep3Done(true));
    dispatch(setCurrentStep(3));
  };

  const prevStep = () => {
    if (currentStep > 0) {
      window.scrollTo(0, 0);
      dispatch(setCurrentStep(currentStep - 1));
    }
  };

  return (
    <div className="mx-auto flex w-full max-w-3xl flex-col items-center justify-center">
      <p className="text-2xl">Enter Information</p>

      <div className="mt-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="my-8 flex w-auto flex-col justify-start rounded-3xl bg-[#F7F7F3] p-8 md:p-16">
            <h3>Contact Information</h3>
            <Flex className="mt-8 flex w-full flex-col gap-5 md:flex-row">
              <FormControl isInvalid={errors.email}>
                <Input
                  name="email"
                  placeholder="Email Address"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="mt-2 text-red-500">{errors.email.message}</p>
                )}
              </FormControl>
              <FormControl isInvalid={errors.phone}>
                <Input
                  name="phone"
                  placeholder="Cell Phone"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("phone")}
                />
                {errors.phone && (
                  <p className="mt-2 text-red-500">{errors.phone.message}</p>
                )}
              </FormControl>
            </Flex>
          </Box>

          <Box className="my-12 flex w-auto flex-col justify-start rounded-3xl bg-[#F7F7F3] p-8 md:p-16">
            <h3>Destination Address</h3>
            <li className="pt-2 text-sm text-gray-600">
              Delivery Options, Sending Paperwork, etc
            </li>
            <Flex className="mt-8 flex w-full flex-col gap-5 md:flex-row">
              <FormControl isInvalid={errors.firstName}>
                <Input
                  name="firstName"
                  placeholder="First Name"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <p className="mt-2 text-red-500">
                    {errors.firstName.message}
                  </p>
                )}
              </FormControl>
              <FormControl isInvalid={errors.lastName}>
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <p className="mt-2 text-red-500">{errors.lastName.message}</p>
                )}
              </FormControl>
            </Flex>
            <Flex className="mt-8 flex w-full flex-col justify-between gap-5 md:flex-row">
              <FormControl isInvalid={errors.city}>
                <Input
                  name="city"
                  placeholder="City"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("city")}
                />
                {errors.city && (
                  <p className="mt-2 text-red-500">{errors.city.message}</p>
                )}
              </FormControl>
              <FormControl isInvalid={errors.state}>
                <Select
                  name="state"
                  placeholder="State"
                  sx={{
                    borderRadius: "full",
                    paddingX: "7",
                    height: "60px",
                  }}
                  {...register("state")}
                >
                  {stateTerritory.map((state, index) => (
                    <option key={index} value={state.state}>
                      {state.state} ({state.abbreviation})
                    </option>
                  ))}
                </Select>
                {errors.state && (
                  <p className="mt-2 text-red-500">{errors.state.message}</p>
                )}
              </FormControl>
            </Flex>
            <Flex className="mt-8 flex w-full flex-col gap-5 md:flex-row">
              <FormControl isInvalid={errors.licenseNumber}>
                <Input
                  name="licenseNumber"
                  placeholder="Driver's License Number"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("licenseNumber")}
                />
                {errors.licenseNumber && (
                  <p className="mt-2 text-red-500">
                    {errors.licenseNumber.message}
                  </p>
                )}
              </FormControl>
              <FormControl isInvalid={errors.instagram}>
                <Input
                  name="instagram"
                  placeholder="Instagram Username (Optional)"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("instagram")}
                />
                {errors.instagram && (
                  <p className="mt-2 text-red-500">
                    {errors.instagram.message}
                  </p>
                )}
              </FormControl>
            </Flex>
            <Flex className="mt-8 flex w-full flex-col gap-5 md:flex-row">
              <FormControl isInvalid={errors.address}>
                <Input
                  name="address"
                  placeholder="Address (No PO Boxes)"
                  borderRadius="full"
                  paddingX="10"
                  paddingY="7"
                  {...register("address")}
                />
                {errors.address && (
                  <p className="mt-2 text-red-500">{errors.address.message}</p>
                )}
              </FormControl>
            </Flex>
          </Box>

          <Box className="my-12 flex w-auto flex-col justify-start rounded-3xl bg-[#F7F7F3] p-8 md:p-16">
            <Flex className="flex w-full items-center">
              <Checkbox
                name="sameAsDestination"
                {...register("sameAsDestination")}
              >
                My billing address is the same as the destination address
              </Checkbox>
            </Flex>
            <Flex className="mt-8 flex w-full flex-col gap-5 md:flex-row">
              <FormControl isInvalid={errors.billingFirstName}>
                <Input
                  name="billingFirstName"
                  placeholder="First Name"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("billingFirstName")}
                  isDisabled={watchBillingCheckbox}
                />
                {errors.billingFirstName && (
                  <p className="mt-2 text-red-500">
                    {errors.billingFirstName.message}
                  </p>
                )}
              </FormControl>
              <FormControl isInvalid={errors.billingLastName}>
                <Input
                  name="billingLastName"
                  placeholder="Last Name"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("billingLastName")}
                  isDisabled={watchBillingCheckbox}
                />
                {errors.billingLastName && (
                  <p className="mt-2 text-red-500">
                    {errors.billingLastName.message}
                  </p>
                )}
              </FormControl>
            </Flex>
            <Flex className="mt-8 flex w-full flex-col justify-between gap-5 md:flex-row">
              <FormControl isInvalid={errors.billingCity}>
                <Input
                  name="billingCity"
                  placeholder="City"
                  borderRadius="full"
                  width={{ base: "100%", md: "300px", lg: "400px" }}
                  paddingX="10"
                  paddingY="7"
                  {...register("billingCity")}
                  isDisabled={watchBillingCheckbox}
                />
                {errors.billingCity && (
                  <p className="mt-2 text-red-500">
                    {errors.billingCity.message}
                  </p>
                )}
              </FormControl>
              <FormControl isInvalid={errors.billingState}>
                <Select
                  name="billingState"
                  placeholder="State"
                  sx={{
                    borderRadius: "full",
                    paddingX: "7",
                    height: "60px",
                  }}
                  {...register("billingState")}
                  isDisabled={watchBillingCheckbox}
                >
                  {stateTerritory.map((state, index) => (
                    <option key={index} value={state.state}>
                      {state.state} ({state.abbreviation})
                    </option>
                  ))}
                </Select>
                {errors.billingState && (
                  <p className="mt-2 text-red-500">
                    {errors.billingState.message}
                  </p>
                )}
              </FormControl>
            </Flex>
          </Box>
          <div className="my-10 flex flex-col items-center justify-center gap-10 md:flex-row">
            <button
              type="button"
              onClick={prevStep}
              className={`flex w-[190px] items-center justify-center gap-3 rounded-full bg-[#001219] px-6 py-5 text-white duration-200 hover:gap-6`}
            >
              <HiOutlineArrowNarrowLeft size={22} />
              Previous
            </button>
            <button
              type="submit"
              className="flex w-[190px] items-center justify-center gap-3 rounded-full bg-[#001219] px-6 py-5 text-white duration-200 hover:gap-6"
            >
              Go Next
              <HiOutlineArrowNarrowRight size={22} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterInformation;
