import React from "react";

const OrderDetails = ({ data }) => {
  return (
    <div className="my-5">
      <div className="flex justify-between">
        <div className="leading-7">
          <p className="pb-10 font-bold">Destination Info</p>
          <p className="text-xl font-bold">
            {`${data.destinationAddress.firstName} ${data.destinationAddress.lastName}`}
          </p>
          <p className="text-wrap">
            {`${data.destinationAddress.address} ${data.destinationAddress.city} ${data.destinationAddress.state}`}
          </p>
          <p>
            Email: <strong>{data.contactInformation.emailAddress}</strong>
          </p>
          <p>
            Phone: <strong>{data.contactInformation.cellPhone}</strong>
          </p>
        </div>
      </div>

      <div className="my-5">
        <div className="mb-4">
          <h4 className="py-6 text-lg font-bold">Order Details</h4>
          <div>
            <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
              <tbody>
                {data?.products?.map((item, index) => (
                  <tr key={index}>
                    <td className="p-2">{item.name} Fee</td>
                    <td className="p-2">$ {item.price.toFixed(2)}</td>
                  </tr>
                ))}
                {data?.puppies?.[0]?.listingInformation?.makeOffer ? (
                  <tr>
                    <td className="py-6 font-bold">Puppy Price</td>
                    <td> $ {data?.puppies?.[0]?.listingInformation?.offerPrice}</td>
                  </tr>
                ) : (
                  <tr>
                    <td className="py-6 font-bold">Puppy Price</td>
                    <td className="py-6 font-bold">
                      $ {data?.puppies?.[0]?.listingInformation?.price}
                    </td>
                  </tr>
                )}

                <tr>
                  <td className="py-6 font-bold">Sub Total</td>
                  <td className="py-6 font-bold">
                    $ {data?.orderSummary?.subtotal?.toFixed(2)}
                  </td>
                </tr>

                {data?.puppies?.[0]?.listingInformation?.makeOffer && (
                  <tr>
                    <td>Save Price</td>
                    <td>
                     - $
                      {(
                        data?.puppies?.[0]?.listingInformation?.price -
                        data?.puppies?.[0]?.listingInformation?.offerPrice
                      ).toFixed(2)}
                    </td>
                  </tr>
                )}

                <tr>
                  <td>Sales Tax</td>
                  <td className="pb-4">
                   + $ {data?.orderSummary?.salesTax?.toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td className="font-bold">Order Total</td>
                  <td className="font-bold">
                    $ {data?.orderSummary?.orderTotal?.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
