import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import { useAllBreeds, useDeleteBreedMutation } from "../../../features/Breeds/Breeds";
import EditBtn from "../../../components/EditBtn";
import DeleteBtn from "../../../components/DeleteBtn";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import RealBreedForm from "./RealBreedForm.jsx";
import { useAllRealBreeds } from "../../../features/RealBreed/realBreedService.js";


const TableRealBreed = () => {
  const { data: realBreeds, isLoading, error } = useAllRealBreeds("/api/realBreeds");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {}, [realBreeds]);

  const filterProduct = realBreeds?.breeds?.map((product, index) => ({
    ...product,
    id: index + 1,
    createdAt: moment(product.createdAt).format(
      "dddd, MMMM Do YYYY, h:mm:ss a",
    ),
  }));
  console.log(filterProduct, "filterProduct");

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteBreedMutation();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/realBreed/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Breed Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleting Breed");
          toast({
            title: "Error deleting breed",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = filterProduct?.filter((item) => item?._id === selectedIdEdit);
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handle} />
        <DeleteBtn onClick={() => handleDeleteClick(props.data._id)} />
      </div>
    );
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading products</p>;

  const columns = [
    {
      headerName: "ID",
      field: "id",
     
    },
    {
      headerName: "Name",
      field: "name",
    },
    // {
    //   headerName: "Category",
    //   field: "breedInformation.category.name",
    // },
    // {
    //   headerName: "Heading",
    //   field: "breedDetails.heading",
    // },
    // {
    //   headerName: "Pet Plan Id",
    //   field: "breedInformation.petPlanBreedId",
    // },
    // {
    //   headerName: "Active",
    //   field: "breedInformation.isActive",
    //   cellRenderer: (params) =>
    //     params.value === true ? (
    //       <span className="text-green-600">Active</span>
    //     ) : (
    //       <span className="text-red-600">Inactive</span>
    //     ),
    // },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      pinned: "right",
      width: "100%",
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  return (
    <div className="my-10">
      

      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={filterProduct}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
      

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}  width={"800px"}>
        <RealBreedForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this color?</p>
      </Modal>
    </div>
  );
};

export default TableRealBreed;
