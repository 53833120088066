import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useToast } from "@chakra-ui/react";
import { uploadFiles } from "../../../utils/uploadFile";
import { ReactSortable } from "react-sortablejs";
import { deleteImages, handleBeforeUpload } from "../../../utils";
import { useCreateReviews, useUpdateReviewMutation } from "../../../features/reviews/reviewsService";

const GalleryForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [form] = Form.useForm();
  const toast = useToast();
  const [media, setMedia] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const createMutation = useCreateReviews();
  const updateMutation = useUpdateReviewMutation();

  console.log(selectedData, "selectedData");

   useEffect(() => {
     if (selectedData && isUpdateModal) {
       // Convert existing media URLs to the format expected by Upload component
       const existingMedia =
         selectedData[0]?.images?.map((url, index) => ({
           uid: `-${index}`, // Unique ID for each file
           name: `image-${index}`, // A name for the file
           status: "done", // Mark it as already uploaded
           url: url, // The actual URL of the image
         })) || [];
 
       setMedia(existingMedia);
 
       form.setFieldsValue({
        familyName: selectedData[0]?.familyName ?? null,
        pupNewName: selectedData[0]?.pupNewName ?? null,
        weight: selectedData[0]?.weight ?? null,
        pupEnjoyCmnt: selectedData[0]?.pupEnjoyCmnt ?? null,
        experience: selectedData[0]?.experience ?? null,
         images: existingMedia,
       });
     }
   }, [selectedData, isUpdateModal]);
 
   const handleBeforeUpload = (file) => {
     const newFile = {
       ...file,
       uid: file.uid,
       name: file.name,
       status: "done",
       originFileObj: file,
     };
 
     setMedia((prev) => [...prev, newFile]);
     return false; // Prevent automatic upload
   };
 
   const onFinish = async (values) => {
     setIsLoading(true);
     console.log(values, "values");
 
     try {
       // Handle media uploads first
       let mediaUrl = null;
       if (media?.length) {
         // Filter out already uploaded media (those with urls) from new ones that need uploading
         const newMedia = media.filter((file) => !file.url);
         const existingMedia = media
           .filter((file) => file.url)
           .map((file) => file.url);
 
         if (newMedia.length) {
           // Upload new media and get their URLs
           const uploadedMediaUrls = await uploadFiles(
             newMedia.map((file) => file.originFileObj),
           );
           mediaUrl = [...existingMedia, ...uploadedMediaUrls];
         } else {
           mediaUrl = existingMedia;
         }
       }
 
       const data = {
        familyName: values.familyName ?? null,
        pupNewName: values.pupNewName ?? null,
        weight: values.weight ?? null,
        pupEnjoyCmnt: values.pupEnjoyCmnt ?? null,
        experience: values.experience ?? null,
         images: mediaUrl,
       };
 
       try {
         if (isUpdateModal && selectedData) {
          await updateMutation.mutateAsync({
            apiUrl: `/api/reviews/${selectedData[0]._id}`,
            data,
           });
 
           toast({
            title: "Review successfully updated!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
         } else {
          await createMutation.mutateAsync({
            apiUrl: "/api/reviews",
            data,
           });
 
           toast({
            title: "Review successfully created!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
         }
 
         // Reset form and clear states
         setMedia([]);
         form.resetFields();
         onClose();
       } catch (error) {
         toast({
           title: error.response?.data?.details?.includes("duplicate")
             ? "Review Already Exists"
             : error.response?.data?.details ||
               error.message ||
               "Error creating Review",
           status: "error",
           duration: 3000,
           isClosable: true,
         });
       }
     } catch (error) {
       toast({
         title: "Error uploading files",
         status: "error",
         duration: 3000,
         isClosable: true,
       });
     } finally {
       setIsLoading(false);
     }
   };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <h2 className="pb-10 text-2xl font-bold">Review</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
      >
        <div className="min-w-full">
          <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
            Customer Information
          </div>
          <Form.Item label="Family Name" name="familyName">
            <Input placeholder="Family Name" disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Pup new name" name="pupNewName">
            <Input placeholder="Pup new name" disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Pup weight" name="weight">
            <Input placeholder="Pup weight" disabled={isLoading} />
          </Form.Item>

          <Form.Item label="Review" name="pupEnjoyCmnt">
            <TextArea rows={3} placeholder="Pup review" disabled={isLoading} />
          </Form.Item>

          <Form.Item label="Experience" name="experience">
            <TextArea
              rows={3}
              placeholder="Pup experience"
              disabled={isLoading}
            />
          </Form.Item>
        </div>

        <div className="flex items-center justify-center">
        <Form.Item
                     label="Media"
                     name={"media"}
                     valuePropName="fileList"
                     getValueFromEvent={normFile}
                   >
                     <ReactSortable
                       animation={200}
                       delayOnTouchStart={true}
                       delay={2}
                       list={media}
                       setList={setMedia}
                       className="flex cursor-move flex-wrap gap-4 pb-4"
                     >
                       {media.map((file) => (
                         <Upload
                           key={file.uid}
                           listType="picture-card"
                           fileList={[file]}
                           showUploadList={{
                             showPreviewIcon: true,
                             showRemoveIcon: true,
                           }}
                           onRemove={() => deleteImages(file, setMedia)}
                           disabled={isLoading}
                         />
                       ))}
                     </ReactSortable>
       
                     {media?.length < 5 && (
                       <Upload
                         listType="picture-card"
                         beforeUpload={handleBeforeUpload}
                         showUploadList={false}
                         multiple
                         disabled={isLoading}
                       >
                         <div>
                           <PlusOutlined />
                           <div style={{ marginTop: 8 }}>Upload</div>
                         </div>
                       </Upload>
                     )}
                     <span className="text-red-600">
                       *Image dimensions should be 1200x800
                     </span>
                   </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {isUpdateModal ? "Update" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GalleryForm;
