import React, { useEffect, useMemo, useState } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";

import { availablePups_tableData } from "../../../utils/data";
import {
  useAllPups,
  useCreatePups,
  useDeletePupsMutation,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";

import { Modal } from "antd";

import ParentForm from "./Parent/ParentForm";

const AvailableParent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateMutation = useUpdatePupsMutation();
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [status, setStatus] = useState(false);
  const [createId, setCreateID] = useState(null);
  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });
  const toast = useToast();

  const extendedClose = () => {
    onClose();
    setSelectedIdEdit(null);
    setSelectedData([]);
  };
  const { data: puppies, isLoading, error } = useAllPups("/api/parents");
  console.log("puppies===>", puppies);

  const createDeleteMutation = useDeletePupsMutation();
  const createMutation = useCreatePups();

  const handle = (id) => {
    setSelectedIdEdit(id);
    onOpen();
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const handleDelete = (id) => {
    const data = {
      status,
    };

    updateMutation.mutate(
      { apiUrl: `/api/parent-status/${selectedId}`, data },

      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Parent Status Updated Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error Updated Parent Status");
          toast({
            title: "Error Updated Parent Status",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = puppies?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  const duplicateClick = (id) => {
    setCreateID(id);
    console.log(id);
  };

  const handleDuplicate = () => {
    console.log("duplicate");
    const filter = puppies?.filter((puppy) => puppy._id === createId);
    console.log("filter===> ", filter);

    const data = {
      parentInformation: {
        parentName: filter[0]?.parentInformation?.parentName,
        category: filter[0]?.parentInformation?.category,
        petPlanParentId: filter[0]?.parentInformation?.petPlanParentId,
        alternateNames: filter[0]?.parentInformation?.alternateNames,
        safeAlternateNames: filter[0]?.parentInformation?.safeAlternateNames,
        relatedBreeds: filter[0]?.parentInformation?.relatedBreeds,
        isActive: filter[0]?.parentInformation?.isActive,
        primaryPhoto: filter[0]?.parentInformation?.primaryPhoto?.url,
        characteristicsPhoto: filter[0]?.parentInformation?.characteristicsPhoto?.url,
        avatarPhoto: filter[0]?.parentInformation?.avatarPhoto,
        coverSales: filter[0]?.parentInformation?.coverSales,
        coverInfo: filter[0]?.parentInformation?.coverInfo,
        parentPhotos: filter[0]?.parentInformation?.parentPhotos,
        
      },
      parentDetails: {
        parentCategory: filter[0]?.parentDetails?.parentCategory,
        slug: filter[0]?.parentDetails?.slug + 1,
        pageTitle: filter[0]?.parentDetails?.pageTitle,
        metaDescription: filter[0]?.parentDetails?.metaDescription,
        heading: filter[0]?.parentDetails?.heading,
        parentTypeMix: filter[0]?.parentDetails?.parentTypeMix,
        energyLevel: filter[0]?.parentDetails?.energyLevel,
        shedding: filter[0]?.parentDetails?.shedding,
        training: filter[0]?.parentDetails?.training,
        temperament: filter[0]?.parentDetails?.temperament,
        adultWeight: filter[0]?.parentDetails?.adultWeight,
        adultHeight: filter[0]?.parentDetails?.adultHeight,
        lifespan: filter[0]?.parentDetails?.lifespan,
        compareCaption: filter[0]?.parentDetails?.compareCaption,
        overview: filter[0]?.parentDetails?.overview,
        characteristics: filter[0]?.parentDetails?.characteristics,
        breeders: filter[0]?.parentDetails?.breeders,
        appearance: filter[0]?.parentDetails?.appearance,
        temperamentDetails: filter[0]?.parentDetails?.temperamentDetails,
        insights: filter[0]?.parentDetails?.insights,
        history: filter[0]?.parentDetails?.history,
        care: filter[0]?.parentDetails?.care,
        video: filter[0]?.parentDetails?.video,

        status: true,
      },
    };

    createMutation.mutate(
      {
        apiUrl: "/api/parent",
        data,
      },
      {
        onSuccess: () => {
          toast({
            title: "Parent created successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setCreateID(null);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        },
        onError: (error) => {
          toast({
            title: error.response?.data?.details?.includes("duplicate")
              ? "Parent Already Exist"
              : error.response?.data?.details ||
                error.message ||
                "Error creating Parent",
            status: "error",
            duration: 3000,
            isClosable: true,
            
          });
          setCreateID(null);
        },
      },
    );
  };

  useEffect(() => {
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
    if (selectedId) {
      handleDelete();
      console.log("selectedId", selectedId);
    }
    if (createId) {
      handleDuplicate();
    }
  }, [selectedIdEdit, selectedId, createId]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const filteredData = availablePups_tableData.filter(
    (item) =>
      item.available.includes(filters.available) &&
      item.details.toLowerCase().includes(filters.details.toLowerCase()) &&
      item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
      item.checkboxes
        .toLowerCase()
        .includes(filters.checkboxes.toLowerCase()) &&
      item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
      item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  );

  console.log(filteredData, "filteredData");
  const sortedPuppies = useMemo(() => {
    return puppies
      ?.filter((puppy) => puppy?.parentDetails?.status)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [puppies]);

  console.log('sorted puppies=>>', sortedPuppies)


  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
    
      </div>
      <div className="my-10">
        <div className="rounded-md border-2 shadow-md border-gray-200">
          <div className="grid grid-cols-12 gap-4 p-4 ">
            {/* Image */}
            <div className="col-span-3 flex flex-col items-start justify-start ml-14">
              <div className="font-bold">Image</div>
            </div>

            {/* Details */}
            <div className="col-span-4 flex flex-col items-center justify-start  text-center">
              <input
                type="text"
                placeholder="Details"
                name="details"
                value={filters.details}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>

            {/* Description */}
            <div className="col-span-4 flex flex-col items-center justify-start  text-center">
              <input
                type="text"
                placeholder="Description"
                name="desc"
                value={filters.desc}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
          </div>

          {sortedPuppies &&
            sortedPuppies.map((item, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-t border-gray-200 p-4"
                key={index}
              >
                {/* Image Section */}
                <div className="col-span-3 flex items-center justify-center">
                  <div className="h-[90%] w-[230px]">
                    <img
                      src={
                        item?.parentInformation?.parentPhotos?.[0] ||
                        "default-image-url"
                      }
                      alt="breed-img"
                      className="h-full w-full rounded-lg object-cover"
                    />
                  </div>
                </div>

                {/* Details Section */}
                <div className="col-span-4 flex flex-col items-start gap-2 border border-gray-200 p-3">
                  <p className="font-bold">
                    Name: {item?.parentInformation?.parentName || "No Name"}
                  </p>
                  <p>
                    <span className="font-semibold">  Parent ID:{" "}</span>
                  
                    {item?.parentInformation?.petPlanParentId || "No Parent Id"}
                  </p>
                  <p>
                    <span className="font-semibold">Related Breed</span>:{" "}
                    {item?.parentInformation?.relatedBreeds || "No Breed "}
                  </p>
                  <p><span className="font-semibold">Category</span>: {item?.parentDetails?.parentCategory || "No Category"}</p>
                  <p><span className="font-semibold">Weight</span>: {item?.parentDetails?.adultWeight || "No Weight "} lbs</p>
                  <p><span className="font-semibold">Height</span>: {item?.parentDetails?.adultHeight || "No Height"} </p>
                  <p><span className="font-semibold">Life span</span>: {item?.parentDetails?.lifespan || "No lifespan"}</p>
                  
                  <div className="mt-2 flex space-x-2">
                    <button
                      onClick={() => handle(item?._id)}
                      className="rounded bg-blue-500 px-2 py-1 text-sm text-white"
                    >
                      Edit
                    </button>
               
                    <button
                      onClick={() => handleDeleteClick(item?._id)}
                      className="rounded bg-red-500 px-2 py-1 text-sm text-white"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => duplicateClick(item._id)}
                      className="rounded bg-[#868686] px-2 py-1 text-sm text-white"
                    >
                      Duplicate
                    </button>
                  
                  </div>
                </div>

                {/* Description Section */}
                <div className="col-span-4 flex flex-col gap-2 border border-gray-200 p-2">
                  <p>
                  <span className="font-semibold">Description</span> :{" "}
                    {item?.parentDetails?.metaDescription || "No Description"}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* <PupsTable /> */}
      <GlobalModalV1 isOpen={isOpen} onClose={extendedClose}>
        <ParentForm
          onClose={extendedClose}
          isUpdateModal={true}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </GlobalModalV1>

      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this Puppy?</p>
      </Modal>
    </div>
  );
};

export default AvailableParent;
