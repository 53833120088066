import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import { useAllDiscounts } from "../features/DiscountsCoupon/discountServise";
import { setFormData } from "../features/buy-now/action";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useGetPupById } from "../features/pups/pupsService";

const ReviewAndPay = ({ breed, puppeis, highestValueCoupon }) => {
  const dispatch = useDispatch();
  const { formData, addedProducts } = useSelector((state) => state.buyNow);

  const param = useParams();


  const { data: pup } = useGetPupById(`/api/puppy/${param.id}`);


  const state = useSelector((state) => state);


  // Filter products
  const selectedProducts = formData.products.filter(
    (item) => addedProducts.includes(item._id) || item.required,
  );

  const productsTotal = selectedProducts.reduce(
    (sum, item) => sum + item.price,
    0,
  );

  const [amountDueToday, setAmountDueToday] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Set default payment method to Credit Card
    if (!formData.paymentMethod) {
      handleFormDataChange("paymentMethod", "Credit Card");
    }
    // Set default payment type to full
    if (!formData.paymentType) {
      handleFormDataChange("paymentType", "full");
    }
  }, []);

  const handleFormDataChange = (key, value) => {
    dispatch(setFormData({ [key]: value }));
  };

  const discountedPrice =
    puppeis?.listingInformation?.price - highestValueCoupon?.value;



  const subTotal = puppeis?.listingInformation?.makeOffer
    ? puppeis?.listingInformation?.offerPrice + productsTotal
    : puppeis?.listingInformation?.price + productsTotal;

  const puppyTotal = puppeis?.listingInformation?.makeOffer
    ? puppeis?.listingInformation?.offerPrice
    : puppeis?.listingInformation?.price;

  // const taxPrice = discountedPrice
  //   ? (discountedPrice + productsTotal) * 0.07
  //   : subTotal * 0.07;
  // // subTotal * 0.07;
  // const totalPrice = discountedPrice
  //   ? discountedPrice + productsTotal + taxPrice
  //   : subTotal + taxPrice;

  const taxPrice = subTotal * 0.07;
  const totalPrice = subTotal + taxPrice;


  useEffect(() => {
    if (formData.paymentType === "reserved400") {
      setAmountDueToday(totalPrice - 400);
    } else {
      setAmountDueToday(totalPrice);
    }

    handleFormDataChange("amountDueToday", amountDueToday);
    handleFormDataChange("totalPrice", totalPrice);
    handleFormDataChange("subTotal", subTotal);
    handleFormDataChange("salesTax", taxPrice);
    handleFormDataChange("discount", highestValueCoupon?.value);
    handleFormDataChange("coupon", highestValueCoupon?.couponCode || "none");
    handleFormDataChange("puppyTotal", puppyTotal);
    handleFormDataChange("additionalProductsTotal", productsTotal);
  }, [formData.paymentType, totalPrice, subTotal]);

  useEffect(() => {
    handleFormDataChange("salesFinal", false);
    handleFormDataChange("agreeToTerms", false);
    handleFormDataChange("agreeSMS", false);
  }, []);

  useEffect(() => {
    // Add validation state to formData
    handleFormDataChange(
      "isValid",
      formData.salesFinal && formData.agreeToTerms && formData.agreeSMS,
    );
  }, [formData.salesFinal, formData.agreeToTerms, formData.agreeSMS]);

  return (
    <div className="mx-auto flex w-full flex-col items-center justify-center">
      {/* Payment Type Selection */}
      <div className="mb-10">
        <div className="mb-10 grid grid-cols-1 gap-4 md:grid-cols-2">
          <div
            className={`flex h-full w-full cursor-pointer items-center justify-center gap-1 rounded-3xl px-10 py-10 duration-200 ${
              formData.paymentType === "full"
                ? pup?.puppyInformation?.gender === "Male"
                  ? "bg-[#5EC0FC]"
                  : "bg-[#FFC0CB]"
                : "bg-[#EDEDE9]"
            } hover:${
              pup?.puppyInformation?.gender === "Male"
                ? "bg-[#5EC0FC]"
                : "bg-[#FFC0CB]"
            }`}
            onClick={() => handleFormDataChange("paymentType", "full")}
          >
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
              <MdCheck />
            </div>
            <p>Adopt Now</p>
          </div>

          {/* This holds for now */}
          <div
            className={`flex h-full w-full cursor-pointer flex-col items-center justify-center gap-1 rounded-3xl px-10 py-10 duration-200 ${
              formData.paymentType === "reserved400"
                ? pup?.puppyInformation?.gender === "Male"
                  ? "bg-[#5EC0FC]"
                  : "bg-[#FFC0CB]"
                : "bg-[#EDEDE9]"
            } hover:${
              pup?.puppyInformation?.gender === "Male"
                ? "bg-[#5EC0FC]"
                : "bg-[#FFC0CB]"
            }`}
            onClick={() => handleFormDataChange("paymentType", "reserved400")}
          >
            <p>Reserve $400</p>
            {/* <p className="text-sm">
    reserve for <strong>07 days</strong>
  </p> */}
          </div>
        </div>
      </div>

      {/* Order Summary */}
      <p className="text-2xl">Order Summary</p>
      <div className="mt-10">
        <div className="rounded-3xl border border-[#001219]/40 p-6 sm:p-8 lg:p-16">
          <div>
            <div className="overflow-x-auto">
              <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
                <tbody>
                  {selectedProducts.map((item, index) => (
                    <tr key={index}>
                      <td className="p-2">{item.name}</td>
                      <td className="p-2 text-right">
                        ${item.price.toFixed(2)}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td className="p-2">Puppy Price</td>
                    <td className="p-2 text-right">
                      {puppeis?.listingInformation?.makeOffer
                        ? puppeis?.listingInformation?.offerPrice?.toFixed(2)
                        : puppeis?.listingInformation?.price?.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Summary and Totals */}
      <div className="mt-10">
        <div className="rounded-3xl border border-[#001219]/40 p-6 sm:p-8 lg:p-16">
          <div className="overflow-x-auto">
            <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
              <tbody>
                <tr>
                  <td className="p-2 font-bold">Sub total</td>
                  <td className="p-2 text-right font-bold">
                    ${subTotal.toFixed(2)}
                  </td>
                </tr>
                {highestValueCoupon && (
                  <tr>
                    <td className="p-2">
                      Coupon: {highestValueCoupon.couponCode}
                    </td>
                    <td className="p-2 text-right">
                      - ${highestValueCoupon.value}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="p-2">Sales Tax</td>
                  <td className="p-2 text-right">${taxPrice.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Order Total */}
      <div className="mt-10">
        <div className="rounded-3xl bg-[#F2F2EE] p-6 sm:p-8 lg:p-16">
          <div className="overflow-x-auto">
            <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
              <tbody>
                <tr>
                  <td className="p-2 font-bold">Order Total</td>
                  <td className="p-2 text-right font-bold">
                    ${totalPrice.toFixed(2)}
                  </td>
                </tr>
                {formData.paymentType === "reserved400" && (
                  <>
                    <tr>
                      <td className="p-2">Amount Due Today</td>
                      <td className="p-2 text-right">$400.00</td>
                    </tr>
                    <tr>
                      <td className="p-2">Remaining Balance</td>
                      <td className="p-2 text-right">
                        ${(totalPrice - 400).toFixed(2)}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Display Available Coupons */}
      {/* <div className="mt-20">
        {filteredCoupons?.map((item, index) => (
          <div
            key={index}
            onClick={() =>
              handleFormDataChange(
                "coupon",
                formData.coupon === item.couponCode ? null : item.couponCode,
              )
            }
            className={`mx-auto mt-10 flex h-full w-[300px] cursor-pointer flex-col gap-1 rounded-3xl bg-${
              formData.coupon === item.couponCode ? "[#5EC0FC]" : "[#EDEDE9]"
            } px-10 py-10 duration-200 hover:bg-[#5EC0FC]`}
          >
            <div className="flex items-center justify-between">
              <p>{item?.couponCode}</p>
              <button className="mt-2 rounded-3xl bg-[#001219] px-6 py-2 text-sm font-semibold text-white">
                Apply
              </button>
            </div>
          </div>
        ))}
      </div> */}

      {/* Terms of Use */}
      <div className="mx-auto mt-10 max-w-3xl">
        <p className="py-4 text-center text-2xl sm:py-6">Terms Of Use</p>
        <p className="text-lg leading-8 ">
          Customer is responsible for understanding our health guarantee in its
          entirety. The puppy will need picked up within 7 days of the
          availability date. For all deposits full payment is due within 7 days
          of when the deposit is made. In order for us to hold puppy outside of
          7 days we charge a $50 per day kennel fee as well as we require that
          it is paid in full ahead of pick up. All kenneling requests must be
          approved by management prior to approval. All deposits are
          non-refundable / non-transferable. All sales are final once order is
          submitted it can not be cancelled. A 3% processing fee will be applied
          to all PayPal transactions.
        </p>
        <Box className="my-6 flex flex-col gap-4 rounded-2xl border border-[#001219]/40  p-4 text-left">
          <Checkbox
            required
            isChecked={formData.salesFinal}
            onChange={(e) =>
              handleFormDataChange("salesFinal", e.target.checked)
            }
            size="lg"
            iconColor="white"
            borderColor="black"
            _checked={{
              "& .chakra-checkbox__control": {
                background: "black",
                borderColor: "black",
              },
            }}
            sx={{
              alignItems: "flex-start !important",
              "& .chakra-checkbox__control": {
                borderRadius: "md",
                marginTop: "4px",
                borderColor: "black",
              },
              "& .chakra-checkbox__label": {
                display: "block",
                marginTop: "0",
              },
            }}
          >
            <p className="text-black">
              By checking this box, I understand that all sales are final. We do
              not allow for cancellation once order is processed.
            </p>
          </Checkbox>
          <Checkbox
            isChecked={formData.agreeToTerms}
            onChange={(e) =>
              handleFormDataChange("agreeToTerms", e.target.checked)
            }
            required
            size="lg"
            iconColor="white"
            borderColor="#001219"
            _checked={{
              "& .chakra-checkbox__control": {
                background: "black",
                borderColor: "black",
              },
            }}
            sx={{
              alignItems: "flex-start !important",
              "& .chakra-checkbox__control": {
                borderRadius: "md",
                marginTop: "4px",
                borderColor: "black",
              },
              "& .chakra-checkbox__label": {
                display: "block",
                marginTop: "0",
              },
            }}
          >
            <p className="text-black">
              By checking this box I agree to the terms of use.
            </p>
          </Checkbox>
          <Checkbox
            required
            isChecked={formData.agreeSMS}
            onChange={(e) => handleFormDataChange("agreeSMS", e.target.checked)}
            size="lg"
            iconColor="white"
            borderColor="#001219"
            _checked={{
              "& .chakra-checkbox__control": {
                background: "black",
                borderColor: "black",
              },
            }}
            sx={{
              alignItems: "flex-start !important",
              "& .chakra-checkbox__control": {
                borderRadius: "md",
                marginTop: "4px",
                borderColor: "black",
              },
              "& .chakra-checkbox__label": {
                display: "block",
                marginTop: "0",
              },
            }}
          >
            <p className="text-black">
              I agree to receive SMS messages from Simply Cavapoos regarding
              important information about the puppy
            </p>
          </Checkbox>
        </Box>
      </div>

      {/* Payment Options */}
      <div className="">
        <h4 className="py-4 text-center text-lg sm:py-6 sm:text-xl">
          Payment Options
        </h4>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div
            className={`flex h-full w-full cursor-pointer items-center justify-center gap-1 rounded-3xl bg-${
              formData.paymentMethod === "Credit Card"
                ? "[#5EC0FC]"
                : "[#EDEDE9]"
            } px-10 py-10 duration-200 hover:bg-[#5EC0FC]`}
            onClick={() => handleFormDataChange("paymentMethod", "Credit Card")}
          >
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
              <MdCheck />
            </div>
            <p>Credit Card</p>
          </div>
          {/* <div
            className={`flex h-full w-full cursor-pointer items-center justify-center gap-1 rounded-3xl bg-${
              formData.paymentMethod === "PayPal" ? "[#5EC0FC]" : "[#EDEDE9]"
            } px-10 py-10 duration-200 hover:bg-[#5EC0FC]`}
            onClick={() => handleFormDataChange("paymentMethod", "PayPal")}
          >
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
              <MdCheck />
            </div>
            <p>PayPal</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ReviewAndPay;
